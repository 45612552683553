// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.home-services-base {
  margin-top: 8rem;

  &__container {
    @media screen and (max-width: 1290px) {
      max-width: 97% !important;
    }
  }

  &__list {
    margin-top: 7rem;
  }
}
