// ABSTRACTS
@import "../../../../../../assets/sass/abstracts/variables";
@import "../../../../../../assets/sass/abstracts/functions";
@import "../../../../../../assets/sass/abstracts/mixins";

.booking-date-list {
  &__item {
    border: 1px solid getColor("dark-color");
    padding: 1.9rem 1.3rem;
    border-radius: 10px;

    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }

  &__icon-btn {
    background: getColor("primary-color") !important;
  }

  &__icon {
    color: getColor("light-color");
    font-size: 2.6rem;
    font-weight: getWeight("bold");
  }

  &__btn-right {
    @include flex-box(flex-end);
    margin-top: 3rem;
  }

  &__btn-group {
    @include flex-box($align-items: center, $flex-direction: column);
  }

  &__btn-text {
    margin-top: 0.1rem;
    font-size: 1.5rem;
    font-weight: getWeight("thick");
    line-height: 21px;
  }
}
