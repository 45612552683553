// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.video-chapter-base {
  margin-top: 2.8rem;

  &__header {
    @include flex-box(space-between, center);
  }

  &__left {
    flex: 1;
  }

  &__btn {
    font-size: 1.5rem !important;
    font-weight: getWeight("extra-thick") !important;
  }
}
