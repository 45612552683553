// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.login {
  &__ref-link {
    margin-top: 3.2rem;
  }

  &__ref-link--alt {
    margin-top: 4.8rem;
    padding-bottom: 5.8rem;
  }
}
