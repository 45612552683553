.admin-table {
  height: 100%;

  &__table {
    height: 469px !important;
  }

  &__table--alt {
    height: 429px !important;
  }

  &__avatar {
    @include flex-box(center, center);
  }

  &__status {
    display: inline-block;
    border-radius: 10px;
    padding: 0.6rem 0.5rem;
    margin-bottom: 0.6rem;
    font-weight: getWeight("thick");

    &::before {
      content: "";
      width: 6px;
      height: 6px;
      border-radius: 9999px;
      display: inline-block;
      margin-right: 0.5rem;
      background: currentColor;
    }
  }

  &__status--success,
  &__status--active {
    background: getColor("accent-2500");
    color: getColor("accent-2400");
  }

  &__status--pending,
  &__status--inactive {
    background: getColor("grey-light-400");
    color: getColor("primary-color");
  }

  .InovuaReactDataGrid__header {
    background: getColor("grey-light-400") !important;
    font-weight: getWeight("bold") !important;
    letter-spacing: 0.05em !important;
    text-transform: uppercase !important;
  }

  .InovuaReactDataGrid__row,
  .InovuaReactDataGrid__header {
    color: getColor("accent-1900") !important;
  }

  .InovuaReactDataGrid__row {
    font-size: 1.4rem !important;
    line-height: 20px !important;
  }

  &__status,
  &__text-alt,
  .InovuaReactDataGrid__header {
    line-height: 14px !important;
    font-size: 1.2rem !important;
  }

  .inovua-react-toolkit-checkbox__icon-wrapper {
    fill: getColor("primary-color") !important;
  }

  .inovua-react-toolkit-checkbox--indeterminate
    .inovua-react-toolkit-checkbox__icon-wrapper
    svg {
    background: getColor("primary-color") !important;
    fill: getColor("grey-light-2200") !important;
  }

  &__text-alt {
    margin-top: 0.6rem;
    display: block;
  }

  &__icon {
    color: getColor("danger-color") !important;
    font-size: 2rem !important;
  }

  &__icon--restore {
    font-size: 2.3rem !important;
    color: getColor("accent-2300") !important;
  }

  &__btn-group {
    @include flex-box(center, center);
  }

  &__btn-group &__btn {
    margin-right: 0.7rem;
    font-weight: getWeight("thick");
    font-size: 1.2rem;
    line-height: 14px;
    color: getColor("accent-1900");
    background: getColor("grey-light-400");
  }
}
