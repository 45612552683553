// ABSTRACTS
@import "../../../../assets/sass/abstracts/variables";
@import "../../../../assets/sass/abstracts/functions";
@import "../../../../assets/sass/abstracts/mixins";

.team-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5.4rem 4.3rem;

  @include respond(tab-port) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include respond(phone) {
    grid-template-columns: 1fr;
  }
}
