*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
  scroll-behavior: smooth;
  -webkit-tap-highlight-color: transparent;
}

html {
  font-size: 62.5%; // 10/16 => 62.5%

  @include respond(tab-port) {
    font-size: 50%; // 8/16 => 50%
  }
}

body {
  box-sizing: border-box;
  min-height: 100vh;
  overflow-x: hidden;
  background: getColor("light-color");
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style-type: none;
  padding: 0;
}
