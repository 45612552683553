// ABSTRACTS
@import "../../../../../assets/sass/abstracts/variables";
@import "../../../../../assets/sass/abstracts/functions";
@import "../../../../../assets/sass/abstracts/mixins";

.staff-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 7rem 2.3rem;
}
