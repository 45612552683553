// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.recruitment-gateway-hero {
  padding-top: 3.9rem !important;
  padding-bottom: 3.5rem !important;

  &__container {
    max-width: 871px !important;
  }

  &__btn {
    &:hover {
      background: getColor("accent-1300") !important;
    }
  }
}
