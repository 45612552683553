// ABSTRACTS
@import "../../../../assets/sass/abstracts/variables";
@import "../../../../assets/sass/abstracts/functions";
@import "../../../../assets/sass/abstracts/mixins";

.home-stat-list {
  @include flex-box(space-between);

  @include respond(phone) {
    display: block !important;
  }
}
