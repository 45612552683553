// ABSTRACTS
@import "../../../../assets/sass/abstracts/variables";
@import "../../../../assets/sass/abstracts/functions";
@import "../../../../assets/sass/abstracts/mixins";

.subscription-history-card {
  background: getColor("light-color");
  box-shadow: 0px 10px 60px rgba(38, 45, 118, 0.08);
  border-radius: 20px;
  padding: 1.6rem 1.4rem;

  &__header {
    line-height: 30px;
    font-weight: getWeight("thick");
  }

  &__highlight {
    font-weight: getWeight("bold");
    font-size: 1.8rem;
    color: getColor("secondary-color");
  }

  &__footer {
    @include flex-box(flex-end, center);
    margin-top: 1rem;
  }

  &__btn-wrapper:not(:last-child) {
    margin-right: 1.2rem;
  }

  &__btn {
    font-size: 1.6rem;
    font-weight: getWeight("thick");
    line-height: 33px;
  }
}
