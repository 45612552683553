// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.not-verified {
  @include respond(phone) {
    flex-direction: column;
  }

  .message-banner__text {
    @include respond(phone) {
      margin-right: 0px;
      margin-bottom: 1rem;
      text-align: center;
    }
  }

  &__btn {
    font-size: 1.4rem !important;
    line-height: 18px !important;
    border-color: rgba(82, 76, 76, 0.38) !important;
    font-weight: getWeight("thick") !important;
  }
}
