// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.demo-auth-dialog-base {
  &__title {
    font-size: 1.9rem !important;
    font-weight: getWeight("extra-thick") !important;
  }

  &__content-text {
    font-size: 1.7rem !important;
    margin-bottom: 0.2rem;
  }

  &__btn-wrapper {
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  &__btn {
    font-weight: getWeight("thick") !important;
    font-size: 1.6rem !important;
  }
}
