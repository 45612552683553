// ABSTRACTS
@import "../../../../../../../assets/sass/abstracts/variables";
@import "../../../../../../../assets/sass/abstracts/functions";
@import "../../../../../../../assets/sass/abstracts/mixins";

.booking-date-card {
  &__container {
    @include flex-box(space-between, center);
  }

  &__left {
    flex-grow: 1;
    margin-right: 0.9rem;
    position: relative;
  }

  &__card {
    background: rgba(getColor("accent-1200"), 0.73);
    border-radius: 15px;
    padding: 1.3rem;
    @include flex-box($align-items: center);
    font-size: 1.7rem;
    cursor: pointer;
    font-weight: getWeight("thick");
  }

  &__calendar-dropdown {
    position: absolute;
    top: 6rem;
    z-index: 2;
  }

  &__calendar {
    width: 100% !important;
  }

  &__calendar-icon {
    margin-right: 1.5rem;
  }

  &__delete-icon {
    color: getColor("danger-color");
    font-size: 2.4rem;
  }
}
