// ABSTRACTS
@import "../../../../../assets/sass/abstracts/variables";
@import "../../../../../assets/sass/abstracts/functions";
@import "../../../../../assets/sass/abstracts/mixins";

.training-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3rem;

  &__item {
    box-shadow: none !important;
  }
}
