// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.student-navbar {
  background: getColor("light-color");
  -webkit-box-shadow: 0px 4px 3px -4px rgba(204, 204, 204, 1);
  -moz-box-shadow: 0px 4px 3px -4px rgba(204, 204, 204, 1);
  box-shadow: 0px 4px 3px -4px rgba(204, 204, 204, 1);

  &__container {
    height: 114px !important;
    max-width: 95% !important;
    @include flex-box(space-between, center);
  }

  &__left {
    flex: 1;
  }

  &__right {
    flex: 2;
    @include flex-box(center, flex-end, column);
  }

  &__heading {
    @include respond(tab-land) {
      display: none;
    }

    @media screen and (min-width: 1400px) {
      display: none;
    }
  }

  &__menu-icon {
    display: none;
    vertical-align: middle;
    font-size: 3.5rem;

    @include respond(tab-land) {
      display: block;
    }

    @media screen and (min-width: 1400px) {
      display: block;
    }
  }

  &__profile-box {
    @include flex-box($align-items: center);
    background: getColor("accent-1700");
    border-radius: 33px;
    padding: 0.3rem 1rem 0.3rem 0.3rem;
    cursor: pointer;
  }

  &__profile-name {
    font-weight: getWeight("extra-thick");
    font-size: 1.8rem;
    line-height: 26px;
    letter-spacing: -0.02em;
    color: getColor("light-color");
    margin-left: 0.8rem;
  }

  &__student-id {
    font-weight: getWeight("thick");
    font-size: 1.5rem;
    line-height: 22px;
    margin-top: 0.5rem;
  }

  &__btn-go-back {
    font-size: 1.5rem !important;
    font-weight: getWeight("extra-thick") !important;
  }
}
