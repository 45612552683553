// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.input-upload-box {
  @include flex-box(space-between, center);
  padding: 1.5rem 0.8rem;
  min-width: 160px;
  cursor: pointer;

  &__placeholder {
    color: getColor("grey-light-1100");
  }

  &__icon {
    vertical-align: middle;
  }
}
