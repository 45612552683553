// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.video-player-base {
  &__main,
  &__empty {
    background: getColor("grey-light-2100");
    border-radius: 20px;
    margin-top: 2rem;
  }

  &__main {
    padding-right: 0.1rem;
  }

  &__empty {
    padding: 2.5rem 2.2rem;
  }

  &__main,
  &__header {
    @include flex-box(space-between);
  }

  &__left {
    flex-basis: 61%;
    margin-right: 4.2rem;
  }

  &__right {
    flex-basis: 39%;
    max-height: 800px;
    overflow: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 1rem;
      border-radius: 30px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    }

    &::-webkit-scrollbar-thumb {
      width: 0.5rem;
      border-radius: 30px;
      background: getColor("grey-light-2000");
    }
  }

  &__btn {
    font-size: 1.5rem !important;
    font-weight: getWeight("extra-thick") !important;
  }

  &__btn--cs {
    color: getColor("light-color");
    padding: 1.2rem 1.6rem;
    background: getColor("accent-2300");

    &:hover {
      background: rgba(getColor("accent-2300"), 0.8);
    }
  }

  &__btn-group {
    @include flex-box(flex-end, $align-items: center);
  }

  &__btn-group &__btn-wrapper:not(:last-child) {
    margin-right: 1rem;
  }
}
