// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.private-session-card {
  padding: 1.3rem 1.9rem;
  background: getColor("light-color");
  box-shadow: -1px 4px 15px rgba(getColor("dark-color"), 0.25);
  border-radius: 13px;
  text-align: center;
  position: relative;

  &__name {
    font-size: 2.5rem;
    line-height: 39px;
    text-transform: capitalize;
    color: getColor("secondary-color");
    margin-bottom: 0.3rem;
  }

  &__price {
    font-weight: getWeight("extra-thick");
    font-size: 2.5rem;
    line-height: 45px;
    letter-spacing: 0.04em;
  }

  &__main {
    margin-bottom: 3.2rem;
  }

  &__description {
    font-weight: getWeight("thin");
    font-size: 1.7rem;
    line-height: 27px;
  }

  &__btn {
    margin-top: 5rem;
    font-weight: getWeight("thick") !important;
    font-size: 1.7rem !important;
    line-height: 30px !important;
  }

  &__action {
    position: absolute !important;
    top: 14px;
    right: 1.6rem;
  }
}
