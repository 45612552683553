// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.empty-content {
  height: 100%;
  @include flex-box(center, center);
  font-family: getFontFamily("primary");

  &__container {
    max-width: 968px !important;
    @include flex-box(center, center, column);
  }

  &__photo {
    width: 450px;
    height: 400px;
    vertical-align: middle;

    @include respond(phone) {
      width: 80%;
      height: 300px;
    }
  }

  &__text {
    font-size: 2.8rem;
    line-height: 60px;
    text-align: center;
    color: getColor("secondary-color");

    @include respond(phone) {
      line-height: 34px;
    }
  }

  &__photo + &__text {
    margin-top: -40px;
  }

  &__loading-spinner-spacer {
    margin-top: 2.5rem;
  }

  &__btn {
    font-size: 1.6rem !important;
    font-weight: getWeight("thick");
  }
}
