// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.pricing-subject {
  &__empty {
    margin-top: 5rem;
    margin-bottom: 9.3rem;
  }
}
