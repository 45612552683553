// ABSTRACTS
@import "../../../../../../../assets/sass/abstracts/variables";
@import "../../../../../../../assets/sass/abstracts/functions";
@import "../../../../../../../assets/sass/abstracts/mixins";

.booking-time-card {
  margin-top: 1rem;

  &__container {
    @include flex-box(space-between, center);
    max-width: 432px;
    margin-left: auto;
  }

  &__left {
    margin-right: 0.9rem;
    flex-grow: 1;
    @include flex-box(space-between, center);
  }

  &__spacer {
    width: 21px;
    height: 3px;
    background: getColor("dark-color");
    margin: 0px 0.7rem;
  }

  &__delete-icon {
    color: getColor("danger-color");
    font-size: 2.4rem;
  }
}
