// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.student-table-subject-preview {
  @include flex-box($align-items: center);
  color: getColor("grey-light-1500");
  font-family: getFontFamily("primary");

  &__left {
    margin-right: 4rem;
  }

  &__icon-box {
    border-radius: 8px;
    width: 64px;
    height: 64px;
    @include flex-box(center, center);
  }

  &__icon {
    font-size: 3.6rem;
  }

  &__title {
    font-weight: getWeight("thick");
    font-size: 1.5rem;
    line-height: 22px;
  }

  &__stat {
    color: getColor("grey-light-600");
    text-align: left;
    font-size: 1.4rem;
    line-height: 21px;
  }

  &__stat-item:not(:last-child) {
    margin-right: 0.5rem;
  }
}
