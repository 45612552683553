// ABSTRACTS
@import "../../../../assets/sass/abstracts/variables";
@import "../../../../assets/sass/abstracts/functions";
@import "../../../../assets/sass/abstracts/mixins";

.modal {
  background: getColor("light-color");
  border-radius: 30px;
  min-width: 684px;
  max-width: 100%;
  max-height: 92vh;
  min-height: 100px;
  overflow: hidden;
  overflow-y: auto;
  position: relative;
  padding: 3.9rem 8.9rem !important;

  &__close-icon-btn {
    font-family: getFontFamily("secondary") !important;
    position: absolute !important;
    right: 1.9rem !important;
    top: 1.6rem !important;
  }

  &__close-icon {
    font-size: 3rem;
    color: getColor("grey-light-1000");
  }
}
