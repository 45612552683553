// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.student-subject-statistic-table {
  &__header {
    @include flex-box(space-between, center);
    margin-bottom: 1.2rem;
  }

  &__heading {
    font-size: 2rem;
    line-height: 36px;
    font-weight: getWeight("thick");
    color: getColor("secondary-color");
  }

  &__btn {
    font-size: 1.4rem;
    line-height: 16px;
    font-weight: getWeight("thick");
    letter-spacing: 1.25px;
    text-transform: uppercase;
    @include flex-box($align-items: center);
  }

  &__btn-icon {
    font-size: 2rem;
    margin-right: 0.9rem;
  }

  &__empty .empty-content__photo {
    width: 420px !important;
    height: 330px !important;
  }
}
