// ABSTRACTS
@import "../../../../assets/sass/abstracts/variables";
@import "../../../../assets/sass/abstracts/functions";
@import "../../../../assets/sass/abstracts/mixins";

.professional-training-course-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 6.7rem 2.2rem;

  @include respond(tab-land) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include respond(phone) {
    grid-template-columns: 1fr;
  }
}
