// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.student-timespent-statistic {
  &__header {
    @include flex-box(flex-end, center);
    margin-bottom: 0.9rem;
  }

  &__main {
    @include flex-box(flex-end);
    padding: 2rem;
    background: getColor("light-color");
    border-radius: 7px;

    @include respond(phone) {
      width: 80%;
      background: red;
      overflow: auto;
      justify-content: center;
    }
  }

  &__btn {
    font-size: 1.2rem;
    line-height: 18px;
    color: getColor("grey-light-600");
    background: getColor("light-color");
    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.25));
    padding: 0.4rem 0.7rem;
    border-radius: 4px;
    font-weight: getWeight("bold");
  }

  &__btn--active {
    background: rgba(225, 225, 225);
  }

  &__btn-wrapper:first-child {
    margin-right: 0.7rem;
  }
}
