// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.student-general-statistic {
  &__container {
    @include flex-box();
  }

  &__left {
    margin-right: 4.2rem;
    text-align: center;
  }

  &__user {
    font-weight: getWeight("bold");
    font-size: 2rem;
    line-height: 2.3rem;
  }

  &__item:not(:last-child) {
    margin-bottom: 2.5rem;
  }

  &__item-top {
    @include flex-box($align-items: center);
    margin-bottom: 0.3rem;
  }

  &__icon {
    font-size: 2.7rem;
    margin-right: 2rem;
  }

  &__stat {
    font-size: 2.4rem;
    line-height: 36px;
    color: getColor("grey-light-1400");
  }

  &__text {
    font-weight: getWeight("bold");
    font-size: 1.3rem;
    line-height: 14px;
    color: getColor("grey-light-2400");
  }
}
