// ABSTRACTS
@import "../../../../assets/sass/abstracts/variables";
@import "../../../../assets/sass/abstracts/functions";
@import "../../../../assets/sass/abstracts/mixins";

.add-training-form {
  &.admin-form {
    max-width: 600px !important;
  }
}
