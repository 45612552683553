// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.private-session-base {
  &__empty .empty-content__photo {
    width: 420px !important;
    height: 330px !important;
  }
}
