// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.navbar {
  background: getColor("light-color");
  z-index: 5;

  &--intersecting,
  &--intersecting-return {
    width: 100%;
    left: 0;
    position: sticky;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    animation-timing-function: linear;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
  }

  &--intersecting {
    animation-name: sticky;
  }

  &--intersecting-return {
    animation-name: stickyReturn;
    animation-duration: 0.4s;
  }

  &--open {
    @include respond(tab-land) {
      position: fixed;
      left: 0;
      width: 100%;
      border-bottom: 0.5px solid getColor("accent-900");
    }
  }

  &__container {
    @include flex-box(space-between);

    @include respond(tab-land) {
      max-width: 97% !important;
    }

    @include respond(tab-port) {
      padding: 1.5rem 0px;
    }
  }

  &__menu {
    display: none !important;

    @include respond(tab-land) {
      @include flex-box($align-items: center);
    }
  }

  &__menu-icon {
    font-size: 2.5rem;
  }

  &__brand-logo {
    width: 150px;
    height: 95.81px;
    vertical-align: middle;

    @include respond(tab-port) {
      width: 97px;
      height: 59.21px;
    }
  }

  &__list {
    flex-grow: 1;
    @include flex-box(flex-end, center);

    @include respond(tab-land) {
      position: fixed;
      z-index: 1;
      left: 0;
      top: 97px;
      width: 100%;
      height: calc(100% - 97px);
      flex-direction: column;
      justify-content: flex-start;
      background: getColor("light-color");
      overflow: hidden;
      overflow-y: auto;
      padding: 1.4rem 0;
    }

    @include respond(tab-port) {
      top: 84px;
      height: calc(100% - 84px);
    }
  }

  &__item {
    font-size: 1.7rem;
    line-height: 33px;
    font-weight: getWeight("thick");

    &:not(:last-child) {
      margin-right: 4rem;

      @include respond(tab-land) {
        margin-right: 0px;
        margin-bottom: 2rem;
      }
    }

    &:last-child {
      margin-left: 4rem;

      @include respond(tab-land) {
        margin-left: 0px;
      }
    }

    &:hover {
      color: getColor("primary-color");
    }
  }

  &__btn-group {
    @include flex-box(space-between, center);

    @include respond(phone) {
      justify-content: center;
      flex-direction: column;
    }
  }

  &__link-btn {
    line-height: 33px !important;
    font-size: 1.7rem !important;
    font-weight: getWeight("thick") !important;

    &:not(:last-child) {
      margin-right: 1.2rem;
    }
  }

  &__btn-group &__link-btn:not(:last-child) {
    @include respond(phone) {
      margin-bottom: 1.1rem;
    }
  }
}
