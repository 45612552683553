// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.wire-transfer-checkout-modal {
  color: getColor("dark-color");
  font-family: Poppins;
  font-style: normal;
  line-height: normal;

  &__heading {
    font-size: 1.6125rem;
    font-weight: 600;
    letter-spacing: 0.1575rem;
    margin: -1rem 0 2rem 0;
  }

  &__item {
    font-size: 1.375rem;
    font-weight: 500;

    strong {
      font-weight: 700;
    }

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }

    &:last-child {
      margin: 2rem 0;
      max-width: 61%;
      font-size: 1rem;
    }
  }

  &__btn {
    letter-spacing: 0.1575rem;
    font-size: 1.5rem;
  }
}
