// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.reauthenticate-dialog {
  &__title {
    @include flex-box(space-between, center);
    font-size: 2.5rem !important;
    font-weight: getWeight("bold") !important;
    font-family: getFontFamily("primary") !important;
  }

  &__text {
    font-size: 1.7rem !important;
    line-height: 24px !important;
  }

  &__close-icon {
    font-size: 2rem !important;
    font-family: getFontFamily("primary") !important;
  }

  &__btn {
    font-weight: getWeight("thick") !important;
    font-size: 1.6rem !important;
  }
}
