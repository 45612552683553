// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.more-action {
  position: relative;
  font-weight: getWeight("default");

  &__icon {
    color: getColor("primary-color");
    font-size: 2rem;
    cursor: pointer;
  }

  &__dropdown {
    position: absolute;
    right: 0;
    text-align: left;
    min-width: 107px;
    background: getColor("light-color");
    z-index: 1;
    border: 1px solid getColor("grey-light-700");
    border-radius: 4px;
  }
}
