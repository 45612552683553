// ABSTRACTS
@import "../../../../assets/sass/abstracts/variables";
@import "../../../../assets/sass/abstracts/functions";
@import "../../../../assets/sass/abstracts/mixins";

.add-chapter-form {
  &__btn {
    font-size: 1.5rem !important;
    font-weight: getWeight("extra-thick") !important;
  }
}
