// ABSTRACTS
@import "../../../../assets/sass/abstracts/variables";
@import "../../../../assets/sass/abstracts/functions";
@import "../../../../assets/sass/abstracts/mixins";

.course-catalog-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2.3rem;
  padding-bottom: 4rem;

  @media screen and (max-width: 1310px) {
    column-gap: 1.8rem;
    grid-template-columns: repeat(3, 1fr);
  }

  @include respond(tab-port) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include respond(phone) {
    grid-template-columns: 1fr;
    row-gap: 1.8rem;
  }
}
