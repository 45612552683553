// ABSTRACTS
@import "../../../../assets/sass/abstracts/variables";
@import "../../../../assets/sass/abstracts/functions";
@import "../../../../assets/sass/abstracts/mixins";

.pricing-tag-list {
  &__item {
    &:not(:last-child) {
      margin-bottom: 2rem;
    }
  }
}
