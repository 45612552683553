// ABSTRACTS
@import "../../../../assets/sass/abstracts/variables";
@import "../../../../assets/sass/abstracts/functions";
@import "../../../../assets/sass/abstracts/mixins";

.dashboard-chart-statistic {
  & {
    min-width: 729px;
    position: relative;
    padding: 0 2.5rem;
    background: yellow;
  }

  &__slide-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2.8rem;
    z-index: 1;
    cursor: pointer;
  }

  &__slide-icon--prev {
    left: -0.8rem;
  }

  &__slide-icon--next {
    right: -0.8rem;
  }

  &__chart-wrapper {
    height: 100%;
    background: #ffffff;
    border-radius: 8px;
  }
}
