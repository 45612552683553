// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.book-private-session-modal-base {
  min-width: 100%;
  padding-left: 3rem !important;
  padding-right: 3rem !important;

  &__content {
    @include flex-box(space-between);

    @media screen and (max-width: 700px) {
      flex-direction: column;
      margin-top: 2.1rem;
    }
  }

  &__form {
    margin-top: 6rem;
    flex-grow: 1;
    margin-left: 3.5rem;

    @media screen and (max-width: 700px) {
      margin-left: 0px;
      margin-top: 3rem;
    }
  }
}
