// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.course-catalog-base {
  margin-top: 2rem;
  padding-bottom: 7rem;

  &__btn {
    font-size: 1.6rem !important;
    font-weight: getWeight("thick");
  }

  &__empty .empty-content__photo {
    width: 420px !important;
    height: 330px !important;
  }
}
