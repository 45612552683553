// ABSTRACTS
@import "../../../../assets/sass/abstracts/variables";
@import "../../../../assets/sass/abstracts/functions";
@import "../../../../assets/sass/abstracts/mixins";

.accounting-solution-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.6rem;

  @include respond(tab-land) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include respond(tab-port) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include respond(phone) {
    grid-template-columns: 1fr;
  }
}
