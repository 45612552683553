// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.social-login {
  @include flex-box();
  margin-top: 2.1rem;

  @include respond(phone) {
    flex-direction: column;
  }

  &__btn {
    @include flex-box(center, center);
    background: getColor("light-color");
    color: rgba(82, 76, 76, 0.38);
    border-color: rgba(82, 76, 76, 0.38) !important;
    line-height: 24px;
    letter-spacing: 0.12em;
    font-size: 1.7rem;
    font-weight: getWeight("thick");
  }

  &__btn-wrapper {
    &:not(:last-child) {
      margin-right: 2rem;

      @include respond(phone) {
        margin-right: 0px;
        margin-bottom: 1rem;
      }
    }
  }

  &__icon {
    margin-right: 2rem;
    vertical-align: middle;
  }
}
