// ABSTRACTS
@import "../../../../assets/sass/abstracts/variables";
@import "../../../../assets/sass/abstracts/functions";
@import "../../../../assets/sass/abstracts/mixins";

.more-action-dropdown-item {
  &__link {
    padding: 1rem;
    display: block;
    font-size: 1.4rem;
    line-height: 21px;
    width: 100%;
    cursor: pointer;
    color: getColor("grey-light-900");

    &:hover {
      background: getColor("grey-light-800");
    }
  }
}
