// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.form {
  &__group--editor {
    height: 250px;
  }

  &__control--editor {
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    padding: 0 !important;
  }

  &__control--editor .ql-toolbar.ql-snow,
  &__control--editor .ql-container.ql-snow {
    border-color: transparent !important;
  }

  &__control--editor .ql-container.ql-snow {
    font-family: getFontFamily("secondary") !important;
  }

  &__control--editor .ql-container .ql-editor[data-placeholder]::before {
    color: getColor("grey-light-1100") !important;
    font-size: 1.7rem !important;
    font-style: normal !important;
  }
}
