// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.subject-table {
  &__head {
    background: getColor("secondary-color") !important;
  }

  &__head-cell,
  &__body-cell,
  &__collapse-cell {
    color: getColor("light-color") !important;
    font-weight: getWeight("bold") !important;
    font-size: 1.4rem !important;
    font-family: getFontFamily("primary") !important;
    line-height: 14px !important;
    letter-spacing: 0.05em !important;
    text-transform: uppercase;
  }

  &__body-cell {
    font-weight: getWeight("thick") !important;
    font-size: 1.3rem !important;
    line-height: 16px !important;
    color: getColor("accent-1900") !important;
  }

  &__body-icon {
    font-size: 2.3rem;
    color: getColor("accent-1900") !important;
  }

  &__collapse-cell {
    color: getColor("accent-1900") !important;
    font-size: 1.2rem !important;
  }
}
