// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.profile-base {
  padding-bottom: 9rem;

  &__group--employment-active {
    margin-top: 5rem;
  }

  &__group-header {
    @include flex-box(space-between, center);

    @include respond(phone) {
      flex-direction: column;
    }
  }

  &__group &__heading {
    @include respond(phone) {
      width: 100%;
      text-align: left;
      margin-bottom: 1.4rem;
    }
  }

  &__group:last-child &__heading {
    @include respond(phone) {
      margin-bottom: 0px;
    }
  }

  &__profile-img-wrapper {
    position: relative;
    cursor: pointer;
    overflow: hidden;
  }

  &__profile-img-icon {
    position: absolute;
    @include absCenter;
    font-size: 3rem;
    color: getColor("light-color");
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s linear;
  }

  &__profile-img-wrapper:hover &__profile-img-icon {
    visibility: visible;
    opacity: 1;
  }

  &__profile-img-wrapper::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: transparent;
    transition: background 0.3s linear;
  }

  &__profile-img-wrapper:hover::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      linear-gradient(0deg, rgba(30, 28, 28, 0.44), rgba(30, 28, 28, 0.44));
  }

  &__profile-studentId {
    text-align: center;
    font-weight: getWeight("thick");
    font-size: 1.5rem;
    line-height: 22px;
    margin-top: 0.9rem;
  }

  &__actions {
    @include flex-box($align-items: center);

    @include respond(phone) {
      width: 100%;
      margin-top: 1.4rem;
      justify-content: flex-end;
    }
  }

  &__action-btn {
    font-size: 1.8rem !important;
    line-height: 33px !important;
  }

  &__action-btn--subscription {
    margin-right: 1.7rem;

    &:hover {
      background: rgba(getColor("dark-color"), 0.8);
    }
  }

  &__form {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.2rem 3.5rem;
    margin-top: 4.3rem;

    @media screen and (max-width: 1310px) {
      column-gap: 2rem;
    }

    @media screen and (max-width: 1000px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include respond(phone) {
      grid-template-columns: 1fr;
      row-gap: 1.5rem;
    }
  }

  &__group-textarea {
    grid-row: span 2;
  }

  &__form--employment &__group-toggle {
    @include respond(phone) {
      margin-top: 3rem;
      margin-bottom: 1rem;
    }
  }
}
