$colors: (
  "primary-color": #f9a22f,
  "secondary-color": #24377c,
  "tertiary-color": #080f34,
  "grey-light-100": #f5f5f5,
  "grey-light-200": #f1f2f8,
  "grey-light-300": #d9dbe1,
  "grey-light-400": #fff2e1,
  "grey-light-500": #57535a,
  "grey-light-600": #828282,
  "grey-light-700": #cfcfde,
  "grey-light-800": #f1f3f5,
  "grey-light-900": #272b30,
  "grey-light-1000": #6f6f6f,
  "grey-light-1100": #828282,
  "grey-light-1200": #f9f9f9,
  "grey-light-1300": #f2f1f1,
  "grey-light-1400": #333333,
  "grey-light-1500": #4f4f4f,
  "grey-light-1600": #e1e1e1,
  "grey-light-1700": #d9d9d9,
  "grey-light-1800": #222222,
  "grey-light-1900": #e0e0e0,
  "grey-light-2000": #a0a9b0,
  "grey-light-2100": #efebf5,
  "grey-light-2200": #e8e8e8,
  "grey-light-2300": #f8f8f8,
  "grey-light-2400": #979797,
  "grey-light-2500": #c7d4e0,
  "grey-light-2600": #c6c2de,
  "accent-100": #524c4c61,
  "accent-200": #06241b,
  "accent-300": #2f327d,
  "accent-400": #f48c06,
  "accent-500": #525596,
  "accent-600": #0c0d0d,
  "accent-700": #626381,
  "accent-800": #b2b3cf,
  "accent-900": #83839a,
  "accent-1000": #545ae7,
  "accent-1100": #393fcf,
  "accent-1200": #d9d9d9,
  "accent-1300": #524c4c,
  "accent-1400": #6fcf97,
  "accent-1500": #001c27,
  "accent-1600": #475be8,
  "accent-1700": #0a0a0a,
  "accent-1800": #0b0d17,
  "accent-1900": #0a0a0d,
  "accent-2000": #0a033c,
  "accent-2100": #31a05f,
  "accent-2200": #151515,
  "accent-2300": #8cca66,
  "accent-2400": #007f00,
  "accent-2500": #cdffcd,
  "accent-2600": #82acf5,
  "accent-2700": #2d9cdb,
  "accent-2800": #71c191,
  "accent-2900": #28c76f,
  "danger-color": #f25b6e,
  "light-color": #ffffff,
  "dark-color": #000000,
);

$weights: (
  "thin": 300,
  "default": 400,
  "thick": 500,
  "extra-thick": 600,
  "bold": 700,
  "extra-bold": 800,
  "bolder": 900,
);

$font-sizes: (
  "extra-small": 1.3rem,
  "small": 1.4rem,
  "medium": 1.6rem,
  "extra-medium": 1.8rem,
  "large": 2rem,
  "extra-large": 2.2rem,
);

$font-family: (
  "font-family-primary": (
    "Poppins",
    sans-serif,
  ),
  "font-family-secondary": (
    "Ubuntu",
    sans-serif,
  ),
);

$base-fonts: (
  "font-size": 1.6rem,
  "font-family":
    map-get(
      $map: $font-family,
      $key: font-family-primary,
    ),
  "line-height": 27px,
  "font-weight": 400,
  "color":
    map-get(
      $map: $colors,
      $key: dark-color,
    ),
);

$button-sizes: (
  "sv1": 2rem 5.2rem,
  "sv2": 1rem 7.1rem,
  "sv3": 1rem 4.1rem,
  "sv4": 1rem 2.5rem,
  "sv5": 1.4rem 2.2rem,
  "sv6": 1.4rem 3.4rem,
  "sv7": 1.4rem 0.9rem,
  "sv8": 0.8rem 1.2rem,
  "sv9": 0.8rem 10.7rem,
  "sv10": 0.8rem 1.4rem,
  "sv11": 1rem 2.1rem,
  "sv12": 1rem 1.5rem,
  "sv13": 0.5rem 1.5rem,
  "sv14": 2rem 4.8rem,
  "sv15": 0.7rem 1.9rem,
  "sv16": 0.5rem 0.6rem,
  "sv17": 0.5rem 1rem,
  "sv18": 1rem 0.7rem,
);

$button-rounded-shape: (
  "rv1": 10px,
  "rv2": 30px,
  "rv3": 20px,
  "rv4": 60px,
  "rv5": 6px,
  "rv6": 8px,
);
