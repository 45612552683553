// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.admin-footer {
  background: getColor("accent-1800");
  color: getColor("grey-light-300");
  margin-top: 3.3rem;

  &__container {
    @include flex-box(space-between, center);
    max-width: 1210px !important;
  }

  &__brand-logo {
    width: 80px;
    height: 68.7px;
    vertical-align: middle;
  }

  &__copyright-text {
    font-size: 1.4rem;
    line-height: 24px;
  }
}
