// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.student-overview-base {
  background: red;

  &__top {
    @include flex-box(space-between, center);

    @include respond(tab-land) {
      flex-direction: column;
    }
  }

  &__general-statistic {
    flex-basis: 32%;
  }

  &__timespent-statistic {
    flex-basis: 50%;
    background: pink;

    @include respond(tab-land) {
      margin-top: 6rem;
    }
  }

  &__bottom {
    margin-top: 6.4rem;
    padding-bottom: 4rem;
    overflow: hidden;
  }
}
