// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.home-finacco-benefit-base {
  margin-top: 8rem;

  @include respond(tab-land) {
    margin-bottom: 18rem;
  }

  @include respond(phone) {
    margin-bottom: 6rem;
  }

  &__container {
    @include flex-box(space-between);

    @media screen and (max-width: 1290px) {
      max-width: 97% !important;
    }

    @include respond(tab-land) {
      display: block !important;
    }
  }

  &__heading {
    line-height: 60px !important;
    font-weight: getWeight("extra-thick") !important;

    @include respond(tab-land) {
      max-width: 65%;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
    }

    @include respond(tab-port) {
      max-width: 70%;
      line-height: 40px !important;
    }

    @include respond(phone) {
      max-width: 100%;
      margin-left: 0px;
      margin-right: 0px;
    }
  }

  &__content {
    font-size: 2.1rem;
    line-height: 39px;
    margin-top: 2.8rem;

    @include respond(tab-land) {
      max-width: 80%;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }

    @include respond(tab-port) {
      max-width: 100%;
      font-size: 2.2rem;
      line-height: 39px;
      margin-left: 0px;
      margin-right: 0px;
    }

    @include respond(phone) {
      font-size: 2rem;
    }
  }

  &__left {
    margin-right: 6.5rem;

    @media screen and (max-width: 1290px) {
      margin-right: 4rem;
    }

    @include respond(tab-land) {
      margin-right: 0px;
      margin-bottom: 4rem;
    }

    @media screen and (max-width: 474px) {
      margin-bottom: 0.3rem;
    }
  }

  &__right {
    @include respond(tab-land) {
      text-align: center;
    }
  }

  &__photo {
    width: 642px;
    height: 417px;
    vertical-align: middle;

    @media screen and (max-width: 1290px) {
      width: 542px;
    }

    @include respond(tab-land) {
      width: 642px;
    }

    @include respond(tab-port) {
      width: 100%;
    }
  }
}
