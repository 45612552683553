// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.status-message {
  height: 100vh;

  &__container {
    @include flex-box(center, center, column, wrap);
    color: getColor("secondary-color");
  }

  &__status {
    font-size: 9rem;
    line-height: 135px;
  }

  &__message {
    font-size: 4rem;
    line-height: 60px;
  }

  &__btn {
    font-weight: getWeight("thick");
    font-size: 1.6rem;
    line-height: 20px;
    margin-top: 4rem;
  }
}
