// ABSTRACTS
@import "../../../../assets/sass/abstracts/variables";
@import "../../../../assets/sass/abstracts/functions";
@import "../../../../assets/sass/abstracts/mixins";

.chart-info {
  @include flex-box(space-between, center);
  padding: 2.3rem 3.1rem;
  color: getColor("accent-1900");

  &__left,
  &__right,
  &__legend {
    @include flex-box($align-items: center);
  }

  &__title {
    font-weight: getWeight("bold");
    font-size: 2rem;
    line-height: 20px;
    margin-right: 2.3rem;
  }

  &__filter {
    width: 80px;
    padding: 1rem;
    @include flex-box(space-between, center);
    border: 1px solid getColor("grey-light-2600");
    border-radius: 6px;
    cursor: pointer;
  }

  &__filter-icon {
    font-size: 2.7rem;
    color: getColor("primary-color");
  }

  &__filter-text {
    font-size: 1.7rem;
    line-height: 18px;
  }

  &__legend {
    font-size: 1.4rem;
    line-height: 20px;
    color: getColor("dark-color");
  }

  &__legend:nth-child(1) {
    margin-right: 2.1rem;
  }

  &__legend:nth-child(1) &__legend-box {
    background: getColor("primary-color");
  }

  &__legend:nth-child(2) &__legend-box {
    background: getColor("accent-2900");
  }

  &__legend-box {
    width: 14px;
    height: 14px;
    display: inline-block;
    border-radius: 9999px;
    margin-right: 0.6rem;
  }
}
