// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.btn {
  &,
  &:visited,
  &:link {
    display: inline-block;
    border-radius: 5px;
    text-align: center;
    border: 1px solid transparent;
    cursor: pointer;
    font-family: inherit;
    transition: all 0.3s ease-in-out;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }

  &-wrapper {
    display: inline-block;
  }

  &-wrapper .is-width-full &__loading {
    @include flex-box(center, center);
  }

  // COLORS
  &--primary {
    background: getColor("primary-color");
    color: getColor("light-color");

    &:hover {
      background: rgba(getColor("primary-color"), 0.8);
    }
  }

  &--secondary {
    background: linear-gradient(
      105.5deg,
      getColor("accent-1000") 19.57%,
      getColor("accent-1100") 78.85%
    );
    color: getColor("light-color");

    &:hover {
      background: linear-gradient(
        105.5deg,
        rgba(getColor("accent-1000"), 0.8) 19.57%,
        rgba(getColor("accent-1100"), 0.8) 78.85%
      );
    }
  }

  &--tertiary {
    background: getColor("accent-1300");
    color: getColor("light-color");

    &:hover {
      background: rgba(getColor("accent-1300"), 0.8);
    }
  }

  &--color-secondary {
    background: getColor("secondary-color");
    color: getColor("light-color");

    &:hover {
      background: rgba(getColor("secondary-color"), 0.8);
    }
  }

  &--gray-1 {
    background: getColor("grey-light-1300");
    color: getColor("grey-light-500");
  }

  &--gray-2 {
    background: getColor("grey-light-700");
    color: getColor("dark-color");

    &:hover {
      background: rgba(getColor("grey-light-700"), 0.8);
    }
  }

  &--dark {
    background: getColor("dark-color");
    color: getColor("light-color");

    &:hover {
      background: rgba(getColor("dark-color"), 0.8);
    }
  }

  // SIZES
  @each $name, $value in $button-sizes {
    &--#{$name} {
      padding: $value;
    }
  }

  // SHAPES
  @each $name, $value in $button-rounded-shape {
    &--#{$name} {
      border-radius: $value !important;
    }
  }
}
