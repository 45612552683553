// ABSTRACTS
@import "../../assets/sass/abstracts/variables";
@import "../../assets/sass/abstracts/functions";
@import "../../assets/sass/abstracts/mixins";

.student-layout {
  &__container {
    @include flex-box();
    height: 100vh;
    overflow: hidden;
  }

  &__main {
    flex-grow: 1;
    height: 100vh;
    background: getColor("grey-light-100");
  }

  &__content {
    overflow: hidden;
    overflow-y: auto;
    height: calc(100vh - 114px) !important;
  }

  &__content-container {
    max-width: 95% !important;
    padding-top: 2rem;

    @media screen and (min-width: 1500px) {
      max-width: 1225px !important;
    }
  }

  &__content .student-base-heading--media-sensitive {
    display: none;
    margin-bottom: 3rem;

    @include respond(tab-land) {
      display: block;
    }

    @media screen and (min-width: 1400px) {
      display: block;
    }
  }
}
