// ABSTRACTS
@import "../../../../assets/sass/abstracts/variables";
@import "../../../../assets/sass/abstracts/functions";
@import "../../../../assets/sass/abstracts/mixins";

.video-playlist-card {
  @include flex-box(space-between, center);
  background: rgba(getColor("light-color"), 0.5);
  border-radius: 10px;
  padding: 1rem;
  cursor: pointer;
  transition: background 0.3s linear;
  position: relative;

  &:hover {
    background: getColor("light-color");
  }

  &:not(:last-child) {
    margin-bottom: 0.8rem;
  }

  &--active {
    background: getColor("light-color");
  }

  &__left {
    @include flex-box($align-items: center);
    overflow: hidden;
  }

  &__thumbnail {
    width: 80px;
    height: 50px;
    vertical-align: middle;
    border-radius: 8px;
    margin-right: 1rem;
  }

  &__thumbnail--pdf {
    width: 30px;
    height: 30px;
    border-radius: 0;
    margin-right: 1.2rem;
  }

  &__content-name {
    font-size: 1.8rem;
    line-height: 27px;
    color: getColor("accent-1300");
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__content-duration {
    font-size: 1.2rem;
    line-height: 18px;
    color: getColor("danger-color");
    font-weight: getWeight("thick");
  }

  &__end {
    margin-left: 1.4rem;
    margin-right: -0.8rem;
  }

  &__icon {
    font-size: 2.7rem;
    vertical-align: middle;
    color: getColor("dark-color");
  }

  &__downloadable {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    right: 0;
  }
}
