// WEIGHT
@each $name, $value in $weights {
  .is-text-#{$name} {
    font-weight: $value !important;
  }
}

// FONT SIZE
@each $name, $value in $font-sizes {
  .is-text-#{$name} {
    font-size: $value;
  }
}

// COLOR
@each $name, $value in $colors {
  .is-text-#{$name} {
    color: $value;
  }
}

// BACKGROUND
@each $name, $value in $colors {
  .bg-#{$name} {
    background-color: $value;
  }
}

// TEXT ALIGNMENT
.is-text-center {
  text-align: center !important;
}

.is-text-right {
  text-align: right !important;
}

.is-text-left {
  text-align: left !important;
}

.is-text-justify {
  text-align: justify !important;
}

// HIDE
.d-none {
  display: none !important;
}

.visibility-hidden {
  visibility: hidden !important;
}

// SHOW
.d-block {
  display: block !important;
}

.d-inline-block {
  display: inline-block !important;
}

.visibility-visible {
  visibility: visible !important;
}

// TEXT TRANSFORMER
.is-upper {
  text-transform: uppercase !important;
}

.is-lower {
  text-transform: lowercase !important;
}

.is-capitalize {
  text-transform: capitalize !important;
}

.is-text-italic {
  font-style: italic !important;
}

// TEXT DECORATION
.is-underline {
  text-decoration: underline !important;
}

// LAYOUT
.container {
  max-width: 1285px;
  height: 100%;
  margin-left: auto !important;
  margin-right: auto !important;

  @include respond(tab-land) {
    max-width: 92% !important;
  }
}

// IMAGE
.img-fluid {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
  vertical-align: middle !important;
}

// FULL WIDTH
.is-width-full {
  width: 100% !important;
}

// FULL Height
.is-height-full {
  height: 100% !important;
}

// BOX OPEN
.box-open {
  visibility: visible;
  opacity: 1;
}

// BOX CLOSE
.box-close {
  visibility: hidden;
  opacity: 0;

  // BOX CLOSE TAB PORT
  &--md {
    @include respond(tab-land) {
      visibility: hidden;
      opacity: 0;
    }
  }
}

// CROP
.crop {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 9999px;
}

.crop-xm {
  width: 37px;
  height: 37px;
  object-fit: cover;
  border-radius: 9999px;
}

.crop-sm {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 9999px;
}

// CURSOR
.is-cursor-pointer {
  cursor: pointer !important;
}

.is-cursor-default {
  cursor: default !important;
}
