// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.professional-training-detail-hero {
  padding-top: 3.9rem !important;
  padding-bottom: 3.5rem !important;

  &__container {
    max-width: 871px !important;
  }

  &__heading {
    margin-bottom: 1.7rem !important;
    line-height: 65px !important;

    @include respond(tab-port) {
      line-height: 35px !important;
    }
  }
}
