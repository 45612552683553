// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.footer {
  background: getColor("accent-600");
  padding-top: 12.1rem;
  color: getColor("light-color");
  position: relative;

  @include respond(phone) {
    padding-top: 5.3rem;
  }

  &__container {
    max-width: 599px !important;

    @include respond(tab-land) {
      &.container {
        max-width: 599px !important;
      }
    }

    @include respond(phone) {
      &.container {
        max-width: 350px !important;
      }
    }

    @media screen and (max-width: 320px) {
      &.container {
        max-width: 92% !important;
      }
    }
  }

  &__line {
    width: 1px;
    height: 17px;
    background: getColor("accent-700");
  }

  &__brand {
    @include flex-box(center, center);

    @media screen and (max-width: 320px) {
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  &__brand-line {
    height: 83px;

    @media screen and (max-width: 320px) {
      display: none;
    }
  }

  &__brand-logo {
    vertical-align: middle;
    margin-right: 4.4rem;
    width: 150px;
    height: 128.81px;

    @media screen and (max-width: 320px) {
      margin-right: 0px;
      width: 105px;
      height: 128.81px;
    }
  }

  &__brand-heading {
    max-width: 219px;
    font-weight: getWeight("extra-thick");
    font-size: 2.2rem;
    line-height: 33px;
    letter-spacing: 0.04em;
    margin-left: 3.4rem;

    @media screen and (max-width: 320px) {
      text-align: center;
    }
  }

  &__newsletter-form {
    @include flex-box($align-items: center, $flex-wrap: wrap);
    margin-bottom: 6.8rem;

    @include respond(phone) {
      display: block !important;
      width: 100%;
      margin-bottom: 6rem;
      text-align: center;
    }
  }

  &__newsletter-heading {
    margin-top: 6rem;
    text-align: center;
    display: block;
    color: getColor("accent-800");
    margin-bottom: 1.5rem;
    font-size: 2rem;
    line-height: 39px;

    @include respond(phone) {
      font-size: 2.3rem;
      line-height: 27px;
      margin-bottom: 2rem;
    }
  }

  &__newsletter-control-group {
    flex-grow: 1;

    &:not(:last-child) {
      margin-bottom: 0px !important;
    }
  }

  &__newsletter-control-group .form__error-wrapper {
    display: none;

    @include respond(phone) {
      display: block;
    }
  }

  &__newsletter-control {
    background: transparent !important;
    border-radius: 80px !important;
    padding: 1.5rem 3rem !important;
    border-color: getColor("accent-900") !important;
    color: getColor("light-color") !important;

    &:focus {
      border-color: getColor("accent-900") !important;
    }

    &::placeholder {
      color: getColor("accent-900") !important;
    }
  }

  &__newsletter-btn {
    font-weight: getWeight("thick");
    font-size: 2.2rem;
    line-height: 33px;
    margin-left: 2rem;

    @include respond(phone) {
      margin-top: 1rem;
    }
  }

  &__newsletter-control-error-wrapper {
    @include respond(phone) {
      display: none;
    }
  }

  &__nav-list {
    @include flex-box(center, center);
    font-size: 1.8rem;
    line-height: 33px;

    @include respond(phone) {
      display: block !important;
    }
  }

  &__nav-line {
    @include respond(phone) {
      display: none;
    }
  }

  &__nav-item {
    &:first-child {
      padding-right: 2.3rem;
    }

    &:nth-child(3) {
      padding-right: 2.6rem;
      padding-left: 2.6rem;
    }

    &:last-child {
      padding-left: 2.3rem;
    }

    @include respond(phone) {
      text-align: center;

      &:first-child {
        padding-right: 0px;
      }

      &:nth-child(3) {
        padding-right: 0px;
        padding-left: 0px;
      }

      &:last-child {
        padding-left: 0px;
      }
    }
  }

  &__nav-link {
    transition: color 0.3s linear;

    &:hover {
      color: getColor("primary-color");
    }
  }

  &__copyright {
    padding: 2rem 0;
    font-size: 1.7rem;
    line-height: 33px;
    text-align: center;
  }
}
