// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.input-date-picker {
  position: relative;

  &__calendar {
    position: absolute;
    top: 60px;
    z-index: 1;
  }

  &__control {
    cursor: pointer;
  }
}
