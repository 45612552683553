// ABSTRACTS
@import "../../../../assets/sass/abstracts/variables";
@import "../../../../assets/sass/abstracts/functions";
@import "../../../../assets/sass/abstracts/mixins";

.video-chapter-list {
  margin-top: 3.7rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.4rem 3.8rem;
}
