// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.form {
  &__phone-group--error &__phone-control,
  &__phone-group--error &__phone-dropdown-btn {
    border: 1px solid getColor("danger-color") !important;
  }

  &__phone-control-wrapper {
    width: 100% !important;
    font-family: inherit !important;
  }

  &__phone-control {
    padding: 3rem 9.5rem 3rem 9.5rem !important;
    width: 100% !important;
    display: block !important;
    line-height: 24px !important;
    letter-spacing: 0.12em !important;
    font-size: 1.6rem !important;
    color: getColor("dark-color") !important;
    border-radius: 10px !important;

    &::placeholder {
      color: rgba(82, 76, 76, 0.38) !important;
    }

    @include respond(tab-land) {
      padding: 3rem 11.9rem !important;
    }
  }

  &__phone-dropdown-btn {
    border-radius: 10px 0px 0px 10px !important;
    width: 80px !important;
  }

  &__phone-dropdown-btn.open,
  &__phone-dropdown-btn .open {
    background: transparent !important;
  }

  &__phone-dropdown-btn .selected-flag {
    width: 80px !important;
    @include flex-box(center);

    &:hover {
      background: transparent !important;
    }
  }
}
