.animate-3s {
  transition: all 0.3s ease-in-out;
}

@keyframes sticky {
  0% {
    top: -10%;
  }
  50% {
    top: -6%;
  }
  70% {
    top: -4%;
  }
  80% {
    top: -2%;
  }
  100% {
    top: 0;
  }
}

@keyframes stickyReturn {
  0% {
    top: -2%;
  }
  50% {
    top: -4%;
  }
  80% {
    top: -6%;
  }
  100% {
    position: static;
  }
}
