// ABSTRACTS
@import "../../../../../assets/sass/abstracts/variables";
@import "../../../../../assets/sass/abstracts/functions";
@import "../../../../../assets/sass/abstracts/mixins";

.level-modal-base {
  &__header {
    @include flex-box(space-between, center);
    margin-bottom: 2.6rem;
  }

  &__title {
    font-weight: getWeight("extra-thick");
    font-size: 2.5rem;
    line-height: 60px;
  }
}
