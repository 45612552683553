// ABSTRACTS
@import "../../../../../assets/sass/abstracts/variables";
@import "../../../../../assets/sass/abstracts/functions";
@import "../../../../../assets/sass/abstracts/mixins";

.subject-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2.5rem 3rem;
}
