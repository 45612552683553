// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.message-banner {
  background: getColor("danger-color");
  width: 100%;
  padding: 1rem 2rem;
  @include flex-box(center, center);
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 20;

  &__text {
    font-weight: getWeight("thick");
    font-size: 1.8rem;
    line-height: 27px;
    margin-right: 3rem;
  }
}
