// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.home-about-base {
  margin-top: 8rem;

  @include respond(tab-port) {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  &__container {
    @media screen and (max-width: 1290px) {
      max-width: 97% !important;
    }
  }

  &__description {
    font-size: 2.1rem;
    text-align: center;
    line-height: 39px;
    margin-top: 2.8rem;
    margin-bottom: 5.5rem;
    max-width: 1101px !important;

    @include respond(tab-port) {
      font-size: 2.2rem;
      line-height: 39px;
      max-width: 100% !important;
      margin-left: 0px !important;
      margin-right: 0px !important;
    }

    @include respond(phone) {
      font-size: 2rem;
    }

    @media screen and (max-width: 528px) {
      margin-bottom: 1rem;
    }
  }

  &__group {
    @include flex-box();

    @include respond(tab-land) {
      display: block !important;
    }
  }

  &__photo {
    vertical-align: middle;
    width: 700px;
    height: 630px;

    @media screen and (max-width: 1270px) {
      width: 560px;
      height: 630px;
    }

    @include respond(tab-land) {
      width: 100%;
      height: 430px;
    }
  }

  &__right {
    margin-left: 5.2rem;
    padding-top: 5.4rem;
    font-size: 1.9rem;
    line-height: 39px;

    @media screen and (max-width: 1302px) {
      margin-left: 4rem;
    }

    @include respond(tab-land) {
      margin-left: 0px;
    }

    @include respond(tab-port) {
      line-height: 24px;
    }

    @media screen and (max-width: 528px) {
      padding-top: 1rem;
    }
  }

  &__right-top {
    margin-bottom: 5rem;

    @include respond(tab-port) {
      margin-bottom: 3rem;
    }
  }

  &__right-heading {
    font-weight: getWeight("extra-thick");
    font-size: 2.3rem;
    line-height: 45px;
    text-align: center;
    letter-spacing: 0.04em;
    margin-bottom: 1.3rem;

    @include respond(tab-port) {
      margin-bottom: 1.1rem;
    }
  }

  &__right-list {
    list-style-type: disc;

    @include respond(tab-land) {
      list-style-type: none;
    }
  }

  &__right-item {
    &:not(:last-child) {
      margin-bottom: 2.8rem;
    }

    @include respond(tab-port) {
      &:not(:last-child) {
        margin-bottom: 2rem;
      }
    }
  }

  &__right-item,
  &__right-text {
    @media screen and (max-width: 1302px) {
      max-width: 80%;
      margin-left: auto;
      margin-right: auto;
    }

    @include respond(tab-land) {
      text-align: center;
    }

    @include respond(phone) {
      max-width: 100%;
      margin-left: 0px;
      margin-right: 0px;
    }
  }
}
