// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.admin-sidenavbar {
  height: 100%;

  &__container {
    padding: 0px 0px 0px 2rem;
    @include flex-box($flex-direction: column);
    background: getColor("light-color");
    border-radius: 10px;
    height: 100%;
  }

  &__navbar {
    margin-top: 0.2rem;
    flex-grow: 1;
  }

  &__brand-logo {
    width: 150px;
    height: 128.81px;
  }

  &__list {
    @include flex-box(space-between, $flex-direction: column);
    height: 100%;
  }

  &__list-top &__item {
    &:not(:last-child) {
      margin-bottom: 1.8rem;
    }

    &:last-child {
      margin-top: 1.2rem;
    }
  }

  &__link {
    font-weight: getWeight("thick");
    font-size: 1.8rem;
    line-height: 23px;
    @include flex-box($align-items: center);
    padding: 1.3rem 7.3rem 1.3rem 1.2rem;
    border-right: 5px solid transparent;
    transition: all 0.3s linear, color 0.2s linear;

    &--active,
    &:hover {
      border-radius: 4px 0px 0px 4px;
      background: getColor("grey-light-400");
      color: getColor("primary-color");
      border-color: getColor("primary-color");
    }
  }

  &__link-icon {
    margin-right: 1.5rem;
  }

  &__list-bottom {
    padding-right: 2rem;
    margin-bottom: 1.5rem;
  }

  &__link-btn {
    font-weight: getWeight("thick") !important;
    font-size: 1.6rem !important;
    line-height: 18px !important;
  }
}
