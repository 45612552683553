// ABSTRACTS
@import "../../../../assets/sass/abstracts/variables";
@import "../../../../assets/sass/abstracts/functions";
@import "../../../../assets/sass/abstracts/mixins";

.home-stat-item {
  @include flex-box($align-items: center);

  @include respond(phone) {
    &:not(:last-child) {
      margin-bottom: 2.7rem;
    }
  }

  &__left {
    margin-right: 2.4rem;

    @media screen and (max-width: 670px) {
      margin-right: 1.5rem;
    }

    @include respond(phone) {
      margin-right: 2.4rem;
    }
  }

  &__photo {
    vertical-align: middle;
    width: 84px;
    height: 84px;

    @include respond(tab-port) {
      width: 50px;
      height: 50px;
    }

    @include respond(phone) {
      width: 84px;
      height: 84px;
    }
  }

  &__stat {
    color: getColor("accent-200");
    font-weight: getWeight("extra-thick");
    font-size: 3rem;
    line-height: 45px;

    @include respond(phone) {
      font-size: 2.5rem;
      line-height: 30px;
    }
  }

  &__label {
    font-size: 1.8rem;
    line-height: 33px;
  }
}
