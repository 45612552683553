// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.subject-statistic-table {
  &__body-row {
    margin-bottom: 2rem !important;
  }

  &__head-cell {
    font-weight: getWeight("extra-thick") !important;
    font-size: 1.4rem !important;
    line-height: 22px !important;
    font-family: getFontFamily("primary") !important;
    color: getColor("grey-light-600") !important;
  }

  &__body-cell {
    font-family: getFontFamily("primary") !important;
    font-size: 1.2rem !important;
    line-height: 18px !important;
    color: getColor("grey-light-1500");
  }

  &__tutor {
    @include flex-box($align-items: center);
  }

  &__icon {
    font-size: 1.7rem;
    margin-right: 0.8rem;
  }
}
