// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.input-checkbox {
  &__group {
    @include flex-box($align-items: center, $flex-wrap: wrap);
  }

  &__label {
    margin-left: 0.5rem;
  }

  &__error-wrapper {
    width: 100%;
  }

  &__error {
    text-align: left !important;
    margin-left: 1.2rem;
  }
}
