// ABSTRACTS
@import "../../../../../../assets/sass/abstracts/variables";
@import "../../../../../../assets/sass/abstracts/functions";
@import "../../../../../../assets/sass/abstracts/mixins";

.level-card {
  padding: 4.6rem 5.9rem 1.5rem 5.9rem;
  background: getColor("light-color");
  box-shadow: 0px 10px 60px rgba(38, 45, 118, 0.08);
  border-radius: 20px;
  position: relative;

  &__header {
    margin-bottom: 4rem;
  }

  &__title {
    color: getColor("secondary-color");
    font-size: 2.4rem;
    line-height: 39px;
    text-align: center;
    text-transform: capitalize;
  }

  &__text {
    @include flex-box(space-between, center);
    font-size: 1.8rem;
    line-height: 36px;
  }

  &__footer {
    text-align: center;
    margin-top: 7rem;
  }

  &__action {
    position: absolute !important;
    top: 24px;
    right: 2rem;
  }
}
