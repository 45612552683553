// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.dialog {
  &__title,
  &__content,
  &__btn {
    font-family: getFontFamily("primary") !important;
  }

  &__title {
    font-size: 1.9rem !important;
    font-weight: getWeight("extra-thick") !important;
  }

  &__content-text {
    font-size: 1.7rem !important;
  }

  &__btn {
    font-weight: getWeight("thick") !important;
    font-size: 1.6rem !important;
  }
}
