.admin-form {
  max-width: 330px !important;

  &__group {
    &:not(:last-child) {
      margin-bottom: 0.8rem !important;
    }
  }

  &__group.form__group--inline .admin-form__control {
    width: auto !important;
  }

  &__label {
    font-size: 1.7rem !important;
    line-height: 36px !important;
    font-weight: getWeight("thick") !important;
    font-family: getFontFamily("secondary") !important;
    color: getColor("grey-light-1800");
  }

  &__control {
    padding: 1.5rem 1.7rem 1.4rem 1.7rem !important;
    border-radius: 8px !important;
    border-color: getColor("dark-color");
    font-family: getFontFamily("secondary") !important;
    font-size: 1.7rem !important;
    line-height: 21px !important;
  }

  &__control--select,
  &__control::placeholder {
    color: getColor("grey-light-1100") !important;
  }

  &__control--upload {
    padding: 1.5rem 1.2rem !important;
  }

  &__group--upload {
    margin-top: 1rem;
  }

  &__group--checkbox {
    text-align: left;
    margin-left: -22px;
    margin-top: -10px;
  }

  &__group--checkbox + &__group--checkbox {
    margin-top: -25px;
  }

  &__group--checkbox .form__error-wrapper {
    margin-top: -11px !important;
  }

  &__btn-group {
    @include flex-box(flex-end);
    margin-top: 3.5rem;
  }

  &__btn {
    font-weight: getWeight("thick") !important;
    font-size: 1.6rem !important;
    line-height: 24px !important;
    font-family: getFontFamily("secondary") !important;
  }

  &__label--booking-date {
    display: block;
    text-align: left;
  }
}
