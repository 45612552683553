// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.contact-base {
  margin-top: 8rem;
  margin-bottom: 9rem;

  &__container {
    @include flex-box(space-between);
    max-width: 1133px !important;

    @include respond(tab-land) {
      display: block !important;
    }
  }

  &__left {
    @include respond(tab-land) {
      max-width: 800px;
    }

    @include respond(tab-port) {
      max-width: 100%;
    }
  }

  &__description {
    font-size: 2.4rem;
    line-height: 39px;
    max-width: 545px;
    margin-top: 2rem;
  }

  &__form {
    margin-top: 4rem;
  }

  &__right {
    margin-top: 4rem;
  }
}
