// ABSTRACTS
@import "../../../../assets/sass/abstracts/variables";
@import "../../../../assets/sass/abstracts/functions";
@import "../../../../assets/sass/abstracts/mixins";

.private-session-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3.6rem 2.8rem;
  padding-bottom: 4rem;

  @include respond(tab-land) {
    gap: 2rem;
  }

  @media screen and (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include respond(phone) {
    grid-template-columns: 1fr;
  }
}
