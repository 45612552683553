// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.subject-card {
  position: relative;
  background: getColor("light-color");
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.25);
  padding-bottom: 1.4rem;
  border-radius: 4px;
  overflow: hidden;

  &__link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
  }

  &__photo {
    vertical-align: middle;
    object-fit: cover;
    height: 200px;
    width: 100%;
    border-radius: 4px 4px 0px 0px;
  }

  &__caption {
    @include flex-box(space-between, center);
    padding: 0 1.4rem;
    margin-top: 1.5rem;
    margin-bottom: 1.3rem;
  }

  &__name {
    font-weight: getWeight("thin");
    font-size: 1.8rem;
    line-height: 27px;
    color: getColor("grey-light-1400");
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 95%;
  }

  &__price {
    font-weight: getWeight("bold");
    font-size: 1.2rem;
    line-height: 18px;
    color: getColor("grey-light-1500");
  }

  &__main {
    @include flex-box(space-between, center);
    padding: 0 1.1rem;
  }

  &__author {
    font-size: 1.2rem;
    line-height: 18px;
    color: getColor("grey-light-1500");
    @include flex-box($align-items: center);
  }

  &__author-icon {
    margin-right: 0.3rem;
    font-size: 1.5rem;
  }

  &__stats {
    font-size: 1.2rem;
    line-height: 18px;
    color: getColor("grey-light-1500");
  }

  &__action,
  &__checkbox-wrapper {
    position: absolute !important;
    top: 6px;
    right: 0.3rem;
  }

  &__checkbox-wrapper {
    right: auto;
    left: 0;
  }

  &__action .more-action__icon {
    color: getColor("light-color") !important;
  }
}
