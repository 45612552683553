// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.pricing-base {
  padding-top: 5rem;
  padding-bottom: 4rem;

  &__heading {
    text-align: center;
    font-weight: getWeight("extra-thick");
    font-size: 3rem;
    line-height: 45px;
    letter-spacing: 0.04em;
    margin-bottom: 1.5rem;
  }

  &__sub-heading {
    font-weight: getWeight("thick");
    font-size: 1.5rem;
    line-height: 22px;
    text-align: center;
    color: getColor("accent-1300");
    margin-bottom: 5.4rem;
  }

  &__list {
    max-width: 1210px !important;
  }

  &__list-group {
    @include flex-box(space-between);

    @include respond(tab-land) {
      flex-direction: column;
    }
  }

  &__list-left {
    flex-grow: 1;

    @include respond(tab-land) {
      margin-bottom: 2.5rem;
    }
  }

  &__list-right {
    flex-grow: 1.5;
  }

  &__levels {
    margin-bottom: 7.3rem;
  }

  &__list-heading {
    text-align: center;
    font-size: 2.3rem;
    line-height: 39px;
    margin-bottom: 5.6rem;

    @include respond(phone) {
      margin-bottom: 5rem;
    }
  }

  &__study-method {
    font-weight: getWeight("thin");
    font-size: 1.8rem;
    line-height: 27px;
  }
}
