// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.scroll-to-top {
  display: inline-block;
  background: getColor("light-color");
  box-shadow: 0px 4px 60px rgba(41, 44, 124, 0.15);
  padding: 2rem 2.2rem;
  border-radius: 9999px;
  position: fixed;
  bottom: 3.2%;
  right: 2%;
  z-index: 1;
  cursor: pointer;

  @include respond(tab-port) {
    padding: 2rem 2.6rem;
  }

  @include respond(phone) {
    display: none;
  }

  &--intersecting {
    z-index: -1;
  }

  &__icon {
    font-size: 2rem;
    vertical-align: middle;
    font-weight: getWeight("bold");
    color: getColor("secondary-color");
  }
}
