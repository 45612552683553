// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.book-private-session-form {
  &__group {
    &:not(:last-child) {
      margin-bottom: 2rem !important;
    }
  }

  &__btn {
    font-size: 1.8rem !important;
    line-height: 33px !important;
    margin-top: 0.6rem;
    font-weight: getWeight("thick");
  }
}
