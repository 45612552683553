// ABSTRACTS
@import "../../../../../assets/sass/abstracts/variables";
@import "../../../../../assets/sass/abstracts/functions";
@import "../../../../../assets/sass/abstracts/mixins";

.student-paid-subject-base {
  margin-top: 3.1rem;

  &__container {
    max-width: 1160px !important;
  }

  &__header {
    @include flex-box(space-between, center);
    margin-bottom: 2.2rem;
  }

  &__heading {
    font-weight: getWeight("thick");
    font-size: 2.2rem;
    line-height: 33px;
    color: getColor("accent-1500");
  }

  &__btn {
    font-weight: getWeight("thick") !important;
    font-size: 1.6rem !important;
    line-height: 18px !important;
  }
}
