// ABSTRACTS
@import "../../../../assets/sass/abstracts/variables";
@import "../../../../assets/sass/abstracts/functions";
@import "../../../../assets/sass/abstracts/mixins";

.team-item {
  text-align: center;

  &__img-wrapper {
    padding: 1.7rem;
    background: getColor("accent-1200");
    border-radius: 30px;

    @include respond(tab-land) {
      padding: 1.5rem;
    }
  }

  &__img {
    width: 100%;
    height: 340px;
    vertical-align: middle;
    border-radius: 30px;
    object-fit: cover;
  }

  &__name {
    font-size: 2.1rem;
    line-height: 39px;
    color: getColor("secondary-color");
  }

  &__orgRole {
    font-weight: getWeight("thick");
    font-size: 1.8rem;
    line-height: 30px;
    color: getColor("primary-color");
  }
}
