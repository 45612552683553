// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.video-feedback-modal-base {
  &-overlay {
    z-index: 101 !important;
  }
}
