// ABSTRACTS
@import "../../assets/sass/abstracts/variables";
@import "../../assets/sass/abstracts/functions";
@import "../../assets/sass/abstracts/mixins";

.auth-layout {
  &__container {
    background: getColor("grey-light-100");
    @include flex-box();
  }

  &__left {
    flex-basis: 50%;
    height: 100vh;
    flex-shrink: 0;

    @media screen and (max-width: 1142px) {
      display: none;
    }

    @include respond(big-desktop) {
      flex-basis: 0;
      flex-grow: 1;
    }
  }

  &__right {
    flex-grow: 1;
    height: 100vh;
    overflow-y: auto;

    @include respond(big-desktop) {
      flex-grow: 0;
      flex-basis: 40%;
    }
  }

  &__right-container.container {
    max-width: 82% !important;

    @media screen and (max-width: 1142px) {
      max-width: 65% !important;
    }

    @include respond(phone) {
      max-width: 82% !important;
    }
  }

  &__brand {
    text-align: center;
    margin-top: 1.3rem;
    margin-bottom: 0.48em;
  }

  &__brand-logo {
    vertical-align: middle;
    width: 130px;
    height: 104.2px;
  }

  &__heading {
    font-weight: getWeight("extra-thick");
    font-size: 3rem;
    line-height: 45px;
    letter-spacing: 0.04em;
    max-width: 390px;

    @include respond(tab-land) {
      & {
        text-align: center;
        max-width: 100%;
      }
    }

    @include respond(phone) {
      font-size: 2.5rem;
    }
  }

  &__form {
    margin-top: 1.1rem;
  }

  &__form-btn-group {
    @include flex-box(center, center);
    margin-top: 5.1rem;
  }

  &__form-btn {
    font-weight: getWeight("extra-thick");
    font-size: 1.9rem;
    line-height: 32px;
    letter-spacing: 0.12em;
    border-color: rgba(82, 76, 76, 0.38) !important;
  }

  &__ref-link {
    font-size: 2rem;
    line-height: 30px;
    letter-spacing: 0.12em;
    text-align: center;
    display: block;
  }
}
