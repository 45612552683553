// ABSTRACTS
@import "../../../../assets/sass/abstracts/variables";
@import "../../../../assets/sass/abstracts/functions";
@import "../../../../assets/sass/abstracts/mixins";

.add-staff-modal-base {
  text-align: center;

  &__photo {
    vertical-align: middle;
    margin-bottom: 1.9rem;
  }
}
