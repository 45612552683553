// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.book-private-session-timestamp-list {
  margin-bottom: 2.1rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, 260px);
  gap: 1.4rem;
}
