// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.hero-item {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 530px;

  &__container {
    @include flex-box($align-items: center, $flex-direction: column);
    color: getColor("light-color");
    padding-bottom: 1.8rem;
  }

  &__heading {
    font-weight: getWeight("bold");
    font-size: 5rem;
    line-height: 75px;
    text-align: center;
    letter-spacing: 0.04em;
    max-width: 662px;
    margin-top: 11.1rem;

    @include respond(phone) {
      max-width: 100%;
      font-size: 3.5rem;
      line-height: 60px;
    }
  }

  &--2 &__heading {
    max-width: 760px !important;

    @media screen and (max-width: 759px) {
      max-width: 100%;
    }
  }

  &__cta {
    margin-top: 4.2rem;
    font-weight: getWeight("extra-thick");
    font-size: 3rem;
    line-height: 45px;
    text-align: center;
    letter-spacing: 0.04em;

    @include respond(phone) {
      font-size: 2.5rem;
      line-height: 40px;
    }
  }

  &__btn {
    margin-top: 4rem;
    font-size: 1.8rem !important;
    line-height: 33px !important;
  }
}
