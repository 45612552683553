// ABSTRACTS
@import "../../../../assets/sass/abstracts/variables";
@import "../../../../assets/sass/abstracts/functions";
@import "../../../../assets/sass/abstracts/mixins";

.accounting-solution-group {
  &:not(:last-child) {
    margin-bottom: 5.4rem;
  }

  &__heading {
    font-weight: getWeight("extra-thick");
    font-size: 3rem;
    line-height: 45px;
    letter-spacing: 0.04em;
    color: getColor("secondary-color");
    margin-bottom: 3.1rem;
  }
}
