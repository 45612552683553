// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.video-playlist-base {
  padding: 2.5rem 2.2rem;
  padding-left: 0px;

  &__heading {
    font-weight: getWeight("thick");
    font-size: 2rem;
    line-height: 30px;
    margin-bottom: 1.3rem;
  }
}
