// ABSTRACTS
@import "../../../../assets/sass/abstracts/variables";
@import "../../../../assets/sass/abstracts/functions";
@import "../../../../assets/sass/abstracts/mixins";

.video-player {
  padding: 2.5rem 2.2rem;
  padding-right: 0px;

  &__video {
    margin-bottom: 1.7rem;
    height: 500px;
  }

  &__name {
    font-size: 2rem;
    line-height: 33px;
    line-height: 20px;
  }
}

video {
  border-radius: 10px !important;
}
