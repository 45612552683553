// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.pricing-subject-hero {
  &__container {
    background: linear-gradient(
        to right,
        rgba(getColor("dark-color"), 0.1),
        rgba(getColor("dark-color"), 0.1)
      ),
      url("../../../assets/images/pricing-subject-hero.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    min-height: 283px !important;
    @include flex-box(center, center);

    @include respond(tab-port) {
      background: getColor("accent-1200");
      min-height: 233px !important;
      padding-top: 5.3rem;
      padding-bottom: 5.3rem;
    }
  }

  &__heading {
    text-align: center;
    font-weight: getWeight("bold");
    font-size: 4rem;
    line-height: 75px;
    text-align: center;
    letter-spacing: 0.04em;
    max-width: 385px;

    @include respond(tab-port) {
      line-height: 50px;
      color: getColor("accent-300");
    }
  }
}
