// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.checkout-form {
  &__btn {
    font-size: 1.6rem !important;
    font-weight: getWeight("extra-thick") !important;
  }
}
