// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.team-base {
  margin-top: 8rem;

  &__heading {
    margin-bottom: 2.8rem;
  }
}
