// ABSTRACTS
@import "../../../../assets/sass/abstracts/variables";
@import "../../../../assets/sass/abstracts/functions";
@import "../../../../assets/sass/abstracts/mixins";

.contact-form {
  &__btn {
    font-weight: getWeight("thick") !important;
    font-size: 2rem !important;
    line-height: 30px !important;
    border-radius: 0px !important;
  }
}
