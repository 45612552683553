// ABSTRACTS
@import "../../../../../assets/sass/abstracts/variables";
@import "../../../../../assets/sass/abstracts/functions";
@import "../../../../../assets/sass/abstracts/mixins";

.pricing-tag-item {
  @include flex-box($align-items: center);
  font-size: 1.8rem;
  line-height: 33px;

  &__icon {
    margin-right: 1rem;
    width: 35.86px;
    height: 37px;
  }
}
