// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.form {
  &__select-group {
    position: relative;
    background: getColor("light-color");
    border: 1px solid rgba(82, 76, 76, 0.38);
    border-radius: 10px;
    padding: 2.1rem 2.3rem 1.7rem 2.3rem;
    line-height: 24px;
    letter-spacing: 0.12em;
    color: rgba(82, 76, 76, 0.38);
    transition: all 0.3s linear;
    cursor: pointer;
  }

  &__select-selected {
    overflow: hidden;
    white-space: nowrap;
  }

  &__select-dropdown-icon-wrapper {
    position: absolute;
    display: inline-block;
    right: 2.3rem;
    top: 50%;
    height: 100%;
    transform: translateY(-50%);
    @include flex-box(center, center);
    background: getColor("light-color");
  }

  &__select-dropdown-icon {
    font-size: 2rem;
    color: getColor("dark-color");
    vertical-align: middle;
    transition: transform 0.3s linear;
  }

  &__select-dropdown-icon--active {
    transform: rotateZ(180deg);
  }

  &__select-dropdown {
    opacity: 0;
    position: absolute;
    visibility: hidden;
    top: 62px;
    left: 0;
    width: 100%;
    height: 0px;
    border-radius: 5px;
    overflow: hidden;
    overflow-y: auto;
    background-color: getColor("light-color");
    box-shadow: 1px 1px rgba(getColor("dark-color"), 0.2);
    z-index: 1;
    color: getColor("dark-color");
    transition: opacity 0.2s linear, height 0.1s linear;
  }

  &__select-dropdown--active {
    opacity: 1;
    visibility: visible;
    min-height: 100px;
    height: auto;
  }

  &__select-dropdown-item {
    padding: 0.8rem 0.9rem;
    transition: all 0.2s linear;

    &:hover {
      background: rgba(getColor("dark-color"), 0.6);
      color: getColor("light-color");
    }
  }

  &__select-dropdown-item--selected {
    background: rgba(getColor("dark-color"), 0.6);
    color: getColor("light-color");
  }

  &__select-loading {
    height: 100px;
    @include flex-box(center, center);
  }

  &__select-fetch-error {
    max-width: 67%;
    height: 100px;
    @include flex-box(center, center);
    margin-left: auto;
    margin-right: auto;
    font-size: 1.5rem;
    text-align: center;
  }

  // Input Secondary
  &__group--secondary &__select-group {
    border: none;
    border-bottom: 1px solid rgba(getColor("dark-color"), 0.8);
    background: transparent;
    border-radius: 0px;
    padding: 1.3rem 0px 0.8rem 0px;
  }

  &__group--secondary &__select-dropdown-icon-wrapper {
    right: 1.3rem;
    background: transparent;
  }

  &__group--secondary &__select-dropdown {
    top: 57px;
  }

  // Input Tertiary
  &__group--tertiary &__select-group {
    border-color: getColor("grey-light-1900");
    font-size: 1.2rem;
    line-height: 18px;
    color: getColor("accent-1300");
    padding: 1.8rem 2rem;
  }

  // Input Error
  &__select-group--error,
  &__group--secondary &__select-group--error,
  &__group--tertiary &__select-group--error {
    border-color: getColor("danger-color") !important;
  }
}
