// ABSTRACTS
@import "../../../../../assets/sass/abstracts/variables";
@import "../../../../../assets/sass/abstracts/functions";
@import "../../../../../assets/sass/abstracts/mixins";

.staff-card {
  background: getColor("light-color");
  border-radius: 20px;
  text-align: center;
  font-family: getFontFamily("primary");
  min-height: 386px;
  position: relative;

  &__photo {
    vertical-align: middle;
    margin-top: -10%;
  }

  &__caption {
    margin-top: 3.4rem;
  }

  &__name {
    font-size: 2.4rem;
    line-height: 39px;
    color: getColor("secondary-color");
    margin-bottom: 0.1rem;
  }

  &__organizationRole {
    font-weight: getWeight("thin");
    font-size: 1.8rem;
    color: getColor("secondary-color");
    margin-bottom: 7.3rem;
  }

  &__summary {
    max-width: 319px;
    font-size: 1.8rem;
    line-height: 36px;
    margin-left: auto;
    margin-right: auto;
  }

  &__action {
    position: absolute !important;
    top: 24px;
    right: 2rem;
  }
}
