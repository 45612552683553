// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.paper {
  max-width: 1029px !important;

  &__title {
    margin-bottom: 0.3rem;
  }

  &__container {
    padding: 1rem 1.5rem;
    background: getColor("grey-light-1200");
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
  }
}
