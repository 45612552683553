// ABSTRACTS
@import "../../../../assets/sass/abstracts/variables";
@import "../../../../assets/sass/abstracts/functions";
@import "../../../../assets/sass/abstracts/mixins";

.accounting-solution-item {
  background: getColor("light-color");
  box-shadow: 0px 10px 60px rgba(38, 45, 118, 0.08);
  border-radius: 20px;
  @include flex-box($flex-direction: column);

  &__top {
    padding: 4rem 5rem 0px 5rem;
    height: 100%;
    @include flex-box(center, center);
  }

  &__top--pb {
    padding-bottom: 4rem;
  }

  &__content {
    font-size: 2.2rem;
    line-height: 39px;
    text-align: center;
    color: getColor("secondary-color");
  }

  &__bottom {
    padding: 2.4rem 3.3rem;
  }

  &__item {
    font-weight: getWeight("thin");
    font-size: 1.7rem;
    @include flex-box($align-items: center);

    &:not(:last-child) {
      margin-bottom: 1.3rem;
    }
  }

  &__icon {
    vertical-align: middle;
    margin-right: 1.5rem;
    width: 25px;
    height: 25px;
  }
}
