// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.professional-training-detail-base {
  margin-top: 5.5rem;
  padding-bottom: 3rem;

  &__btn-group {
    @include flex-box(center, center);
    margin-bottom: 1.9rem;

    @media screen and (max-width: 342px) {
      flex-direction: column;
    }
  }

  &__btn-group &__btn:first-child {
    margin-right: 4rem;

    @media screen and (max-width: 342px) {
      margin-right: 0px;
      margin-bottom: 0.8rem;
    }
  }

  &__btn {
    font-size: 1.8rem !important;
    line-height: 33px !important;
  }

  &__heading {
    font-weight: getWeight("extra-thick");
    font-size: 2.5rem;
    line-height: 45px;
    letter-spacing: 0.04em;
    color: getColor("secondary-color");
    text-align: center;
  }

  &__heading--overview {
    margin-bottom: 0.9rem;
  }

  &__heading--study-structure {
    margin-top: 2.8rem;
  }

  &__levels {
    margin-top: 3.4rem;
  }

  &__table-container {
    margin-top: 7rem;
  }
}
