// ABSTRACTS
@import "../../../../assets/sass/abstracts/variables";
@import "../../../../assets/sass/abstracts/functions";
@import "../../../../assets/sass/abstracts/mixins";

.contact-address {
  &__item {
    @include flex-box($align-items: center);

    &:not(:last-child) {
      margin-bottom: 7rem;
    }
  }

  &__item-icon {
    font-size: 3.4rem;
  }

  &__item-right {
    margin-left: 2.1rem;
  }

  &__item-title {
    font-weight: getWeight("extra-bold");
    font-size: 2.5rem;
    line-height: 30px;
    letter-spacing: 0.04em;
  }

  &__item-content {
    font-size: 2rem;
    line-height: 25px;
    color: getColor("primary-color");
    margin-top: 0.5rem;
    max-width: 402px;
    font-style: normal;
  }
}
