// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.chart {
  &,
  &-wrapper {
    width: 100% !important;
    min-height: 259px;
  }

  &__empty {
    height: 100%;
    @include flex-box(center, center, column);
  }

  &__empty-heading {
    font-size: 1.7rem;
    font-weight: getWeight("bold");
    line-height: 25px;
  }

  &__empty-content {
    font-size: 1.5rem;
    line-height: 19px;
  }

  &__loading-wrapper {
    @include flex-box(center, center);
    height: 100%;
  }
}
