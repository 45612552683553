// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.recruitment-gateway-base {
  margin-top: 6rem;
  margin-bottom: 6.4rem;

  &__detail {
    font-size: 2rem;
    line-height: 39px;
    margin-bottom: 2.3rem;
  }

  &__subheading {
    font-size: 2rem;
    line-height: 39px;
    font-weight: getWeight("bold");
    margin-bottom: 0.3rem;
  }

  &__item {
    font-size: 1.8rem;
    line-height: 33px;

    &:not(:last-child) {
      margin-bottom: 2.4rem;
    }
  }

  &__services-list {
    list-style-type: disc;
    padding-left: 2.1rem;
  }
}
