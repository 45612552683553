// ABSTRACTS
@import "../../../../assets/sass/abstracts/variables";
@import "../../../../assets/sass/abstracts/functions";
@import "../../../../assets/sass/abstracts/mixins";

.dashboard-base {
  &__container {
    display: grid;
    grid-template-columns: 2fr 1fr;
    // grid-template-rows: 445px auto;
    gap: 2rem 1.9rem;
    // background: blue;
  }
}
