// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.accounting-solution-base {
  margin-top: 6rem;
  margin-bottom: 6.4rem;

  &__container {
    @media screen and (max-width: 1280px) {
      padding: 0 1.2rem;
    }

    @include respond(tab-land) {
      padding: 0;
    }
  }
}
