// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.forgot-password {
  &__ref-link {
    margin-top: 4.7rem;
    padding-bottom: 1.9rem;
  }
}
