// ABSTRACTS
@import "../../../../assets/sass/abstracts/variables";
@import "../../../../assets/sass/abstracts/functions";
@import "../../../../assets/sass/abstracts/mixins";

.video-chapter-card {
  background: getColor("accent-2200");
  font-weight: getWeight("bold");
  font-size: 1.3rem;
  line-height: 20px;
  color: getColor("light-color");
  padding: 1rem;
  border-radius: 3px;
  cursor: pointer;

  &__container {
    position: relative;
  }

  &--active {
    background: getColor("primary-color");
  }

  &__action {
    position: absolute !important;
    @include absCenter;
    left: auto;
    right: -2.4rem;
  }

  &__action .more-action__dropdown {
    z-index: 2 !important;
  }

  &__action .more-action__icon {
    color: getColor("light-color") !important;
  }
}
