// ABSTRACTS
@import "../../../../../assets/sass/abstracts/variables";
@import "../../../../../assets/sass/abstracts/functions";
@import "../../../../../assets/sass/abstracts/mixins";

.student-profile {
  &__paper-profile-summary {
    @include flex-box($align-items: center);
    margin-bottom: 2.7rem;
  }

  &__paper-profile-photo {
    vertical-align: middle;
    margin-right: 1.5rem;
  }

  &__paper-profile-name {
    font-weight: getWeight("bold");
    font-size: 2rem;
    line-height: 23px;
    margin-bottom: 0.7rem;
  }

  &__paper-profile-status {
    font-weight: getWeight("extra-thick") !important;
    font-size: 1.6rem !important;
    line-height: 18px !important;
  }

  &__paper-profile-student-id {
    font-size: 1.8rem;
    margin-bottom: 0.5rem;
    line-height: 22px;
    font-weight: getWeight("thick");
  }

  &__paper-profile-detail-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 2rem 1.3rem;
  }

  &__paper-profile-item-title {
    line-height: 13px;
    margin-bottom: 1.2rem;
    color: getColor("grey-light-500");
  }

  &__paper-profile-item-content {
    font-weight: getWeight("thick");
    line-height: 13px;
    color: getColor("accent-1900");
  }

  &__paper--employment {
    margin-top: 2rem;
  }

  &__paper--employment .paper__title {
    font-weight: getWeight("bold");
    font-size: 2.2rem;
    line-height: 33px;
  }

  &__paper--employment .paper__container {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}
