// ABSTRACTS
@import "../../../../../../../assets/sass/abstracts/variables";
@import "../../../../../../../assets/sass/abstracts/functions";
@import "../../../../../../../assets/sass/abstracts/mixins";

.time {
  flex-grow: 1;
  text-align: center;

  &__card {
    background: rgba(getColor("primary-color"), 0.83);
    border-radius: 15px;
    padding: 1.3rem;
    @include flex-box($align-items: center);
    font-size: 1.5rem;
    cursor: pointer;
    font-weight: getWeight("thick");
    color: getColor("light-color");
  }

  &__container {
    position: relative;
  }

  &__card-dropdown {
    position: absolute;
    z-index: 2;
    top: 5.9rem;
    background: getColor("grey-light-2300");
    width: 100%;
    padding: 1.5rem 1rem;
    box-shadow: 1px 1px rgba(getColor("dark-color"), 0.2);
    border-radius: 5px;
    height: 0;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s linear, height 0.2s linear;
  }

  &__card-dropdown--active {
    height: auto;
    visibility: visible;
    opacity: 1;
  }

  &__group {
    @include flex-box(center, center);
  }

  &__icon {
    margin-right: 1.5rem;
    font-size: 1.8rem;
  }

  &__btn {
    margin-top: 0.6rem;
    font-weight: getWeight("thick");
  }

  &__input,
  &__ampm {
    font-size: 2.1rem;
    font-weight: getWeight("extra-thick");
  }

  &__input {
    padding: 0px 0.2rem;
  }

  &__ampm {
    margin-left: 0.5rem;
    cursor: pointer;
  }
}
