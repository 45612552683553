// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.page-loading {
  height: 100vh;

  &__container {
    @include flex-box(center, center, column);
    position: relative;
  }

  &__spinner {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 49%;
    z-index: 2;
  }
}
