// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.admin-navbar {
  padding: 0 2.2rem;
  height: 90px;
  border-radius: 8px;
  background: getColor("light-color");
  color: getColor("dark-color");
  @include flex-box(center, center);

  &__container {
    width: 100%;
    @include flex-box(space-between, center);
  }

  &__heading {
    font-weight: getWeight("bold");
    font-size: 2.8rem;
    line-height: 34px;
    margin-bottom: 0.4rem;
  }

  &__subheading {
    line-height: 18px;
    color: getColor("grey-light-500");
  }

  &__right {
    @include flex-box($align-items: center);
  }

  &__profile {
    @include flex-box($align-items: flex-end, $flex-direction: column);
  }

  &__profile-name {
    font-weight: getWeight("thick");
    font-size: 1.8rem;
    line-height: 23px;
  }

  &__profile-img {
    margin-left: 1.8rem;
    vertical-align: middle;
  }

  &__profile-role {
    font-size: 1.4rem;
    line-height: 16px;
    color: getColor("grey-light-600");
  }

  &__profile-img-wrapper {
    position: relative;
  }

  &__profile-img-online {
    position: absolute;
    bottom: 6px;
    right: 5px;
  }
}
