// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.signup {
  &__control--select .form__select-dropdown {
    top: 70px !important;

    @include respond(tab-port) {
      top: 58px !important;
    }
  }

  &__ref-link {
    margin-top: 1.3rem;
    padding-bottom: 2.5rem;
  }

  &__checkbox-label-text {
    font-weight: getWeight("thick");
    font-size: 1.7rem;
  }

  &__checkbox-label-link {
    font-weight: getWeight("extra-thick");
  }

  &__group--checkbox {
    margin-left: -1.2rem;
  }
}
