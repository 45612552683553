// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.student-progress-bar {
  &__progress-text {
    font-size: 1.4rem;
    line-height: 21px;
    color: getColor("dark-color");
    text-align: left;
    font-weight: getWeight("thick");
  }

  &__progress-track {
    height: 5.4px;
    border-radius: 8px;
    background: getColor("grey-light-2500");
  }

  &__progress-thumb {
    transition: width 03s linear;
    background: getColor("accent-2700");
    height: 5.4px;
    border-radius: 8px;
  }

  &__progress-thumb--completed {
    background: getColor("accent-2800");
  }
}
