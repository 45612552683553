// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.book-private-session-date {
  margin-bottom: 2.1rem;

  &__calendar {
    width: 100%;
  }
}
