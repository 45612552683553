// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.levels {
  @include flex-box(center);

  &__container {
    text-align: center;
    display: grid;
    gap: 1rem 2rem;

    @include respond(phone) {
      grid-template-columns: repeat(3, 1fr) !important;
    }

    @media screen and (max-width: 445px) {
      grid-template-columns: repeat(2, 1fr) !important;
      width: 100%;
    }
  }

  &__item-wrapper {
    @media screen and (max-width: 445px) {
      width: 100% !important;
    }
  }

  &__item {
    font-weight: getWeight("extra-thick") !important;
    font-size: 1.6rem !important;
    line-height: 20px !important;
    color: getColor("accent-2000");

    @media screen and (max-width: 445px) {
      width: 100% !important;
    }
  }

  &__item:hover,
  &__item--active {
    background: getColor("accent-400");
    color: getColor("light-color");
    box-shadow: 0px 15px 50px rgba(255, 102, 82, 0.2);
  }
}
