// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.home-testimonial-base {
  margin-top: 8rem;

  &__container {
    background: url("../../../assets/images/home-testimonial-bg.svg");
    background-repeat: no-repeat;
    min-height: 700px;
    position: relative;

    @include respond(tab-land) {
      &.container {
        max-width: 783px !important;
        min-height: 724px;
        background: url("../../../assets/images/home-testimonial-bg-sm.svg");
      }
    }

    @media screen and (max-width: 783px) {
      &.container {
        max-width: 92% !important;
      }
    }
  }

  &__heading {
    position: absolute;
    right: 5%;
    top: 0;

    @include respond(tab-land) {
      right: 0;
      top: -100px;
      text-align: center;
      width: 100%;
    }

    @include respond(phone) {
      top: -60px;
    }
  }

  &__main {
    @include flex-box();
    position: absolute;
    right: 0;
    top: 8%;

    @include respond(tab-land) {
      flex-direction: column;
      align-items: center;
      left: 50%;
      top: 2%;
      transform: translateX(-50%);
    }

    @include respond(phone) {
      left: 0;
      transform: translateX(0%);
    }
  }

  &__img {
    vertical-align: middle;
    width: 400px;
    height: 567.31px;

    @include respond(tab-land) {
      height: 300px;
      margin-bottom: -4%;
      position: relative;
    }

    @include respond(phone) {
      width: 100%;
    }
  }

  &__img--lazy {
    border-radius: 20px;
  }

  &__content {
    width: 533px;
    height: 358px;

    background: getColor("light-color");
    box-shadow: 0px 94px 200px rgba(21, 21, 21, 0.15);
    margin-top: auto;
    margin-bottom: auto;
    margin-left: -6%;
    padding: 4.2rem 1.4rem 3.2rem 3.2rem;

    @include respond(tab-land) {
      width: 616px;
      height: 298px;
      margin-left: 0;
      padding: 3.2rem;
      padding-right: 2rem;
    }

    @include respond(phone) {
      width: 100%;
      height: auto;
    }
  }

  &__author-name {
    font-weight: getWeight("extra-thick");
    font-size: 3rem;
    line-height: 45px;
    letter-spacing: 0.04em;
    margin-bottom: 5rem;

    @include respond(phone) {
      margin-bottom: 3rem;
    }
  }

  &__author-testimonial,
  &__author-testimonial-end {
    font-style: normal;
    font-weight: getWeight("thin");
    font-size: 1.8rem;
    line-height: 27px;
    display: block;
  }

  &__author-testimonial-end {
    margin-top: 1.5rem;
  }
}
