// ABSTRACTS
@import "../../assets/sass/abstracts/variables";
@import "../../assets/sass/abstracts/functions";
@import "../../assets/sass/abstracts/mixins";

.admin-layout {
  background: getColor("grey-light-100");
  font-family: getFontFamily("secondary");
  color: getColor("accent-1900");
  padding: 3rem 0 0 0;
  min-height: 100vh;
  @include flex-box(center, center);

  &__container {
    @include flex-box();
    max-width: 1310px !important;
    min-width: 1310px !important;
    height: 610px !important;
  }

  &__right {
    flex-grow: 1;
    margin-left: 2.8rem;
    @include flex-box($flex-direction: column);
  }

  &__content {
    padding-top: 3.9rem;
    height: 100%;
    overflow: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 1rem;
      border-radius: 30px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    }

    &::-webkit-scrollbar-thumb {
      width: 0.5rem;
      border-radius: 30px;
      background: getColor("grey-light-2000");
    }
  }

  &__content-header {
    margin-top: 2.63rem;
    @include flex-box(flex-end);
  }

  &__content-main {
    margin-top: 5.2rem;
  }

  &__content-btn {
    font-weight: getWeight("thick") !important;
    line-height: 18px !important;
    font-size: 1.6rem;
  }
}
