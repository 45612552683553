// ABSTRACTS
@import "../../../../assets/sass/abstracts/variables";
@import "../../../../assets/sass/abstracts/functions";
@import "../../../../assets/sass/abstracts/mixins";

.home-services-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 5.8rem 2.8rem;

  @media screen and (max-width: 1290px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include respond(tab-land) {
    grid-template-columns: repeat(2, 1fr);
  }

  @include respond(phone) {
    grid-template-columns: repeat(1, 1fr);
  }
}
