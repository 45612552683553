// ABSTRACTS
@import "../../assets/sass/abstracts/variables";
@import "../../assets/sass/abstracts/functions";
@import "../../assets/sass/abstracts/mixins";

.video-layout {
  &__navbar-wrapper {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 100;
  }

  &__main {
    margin-top: 13.5rem;
    padding-bottom: 2rem;
  }
}
