// ABSTRACTS
@import "../../../../../assets/sass/abstracts/variables";
@import "../../../../../assets/sass/abstracts/functions";
@import "../../../../../assets/sass/abstracts/mixins";

.booking-date {
  &__btn {
    font-weight: getWeight("extra-thick");
    margin-top: 1.5rem;
  }

  &__error-wrapper &__error {
    text-align: center !important;
  }
}
