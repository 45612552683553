// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2;
  background: rgba($color: getColor("accent-1900"), $alpha: 0.7);

  &--font-2 {
    font-family: getFontFamily("secondary");
  }

  &__container {
    @include flex-box(center, center);
    max-width: 1237px !important;
  }
}
