// ABSTRACTS
@import "../../../../assets/sass/abstracts/variables";
@import "../../../../assets/sass/abstracts/functions";
@import "../../../../assets/sass/abstracts/mixins";

.subscription-history-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2.3rem 2.8rem;
  padding-bottom: 4rem;

  @include respond(phone) {
    grid-template-columns: 1fr;
  }
}
