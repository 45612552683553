// ABSTRACTS
@import "../../../../assets/sass/abstracts/variables";
@import "../../../../assets/sass/abstracts/functions";
@import "../../../../assets/sass/abstracts/mixins";

.training-modal-base {
  &__title {
    font-weight: getWeight("extra-thick");
    font-size: 2.5rem;
    line-height: 60px;
  }

  &__header {
    margin-bottom: 2.6rem;
  }

  &__list {
    grid-template-columns: repeat(3, 1fr) !important;
  }

  &__list .training-list__item {
    box-shadow: 0px 10px 60px rgba(38, 45, 118, 0.08) !important;
  }
}
