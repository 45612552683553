// ABSTRACTS
@import "../../../../../../assets/sass/abstracts/variables";
@import "../../../../../../assets/sass/abstracts/functions";
@import "../../../../../../assets/sass/abstracts/mixins";

.student-paid-subject-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem 1rem;
}
