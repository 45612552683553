// ABSTRACTS
@import "../../../../assets/sass/abstracts/variables";
@import "../../../../assets/sass/abstracts/functions";
@import "../../../../assets/sass/abstracts/mixins";

.stats-card {
  background: getColor("light-color");
  border-radius: 6px;
  padding: 1.13rem 2.5rem 1.4rem 2.5rem;

  &__container {
    @include flex-box(space-between, center);
  }

  &__title {
    font-weight: getWeight("thick");
    font-size: 2.2rem;
    line-height: 24px;
    margin-bottom: 0.9rem;
  }

  &__subtitle {
    color: getColor("grey-light-600");
    font-size: 1.4rem;
    line-height: 16px;
  }

  &__icon {
    font-size: 5.7rem;
    font-weight: getWeight("bold");
  }

  &__icon-wrapper {
    @include flex-box(center, center);
    background-color: getColor("primary-color");
    width: 100px;
    height: 100.29px;
    border-radius: 99999px;
    color: getColor("light-color");
  }

  &__icon-wrapper--secondary {
    background-color: getColor("accent-1400");
  }
}
