// ABSTRACTS
@import "../../../../assets/sass/abstracts/variables";
@import "../../../../assets/sass/abstracts/functions";
@import "../../../../assets/sass/abstracts/mixins";

.video-playlist-card-sortable {
  &:not(:last-child) {
    margin-bottom: 0.8rem;
  }
}
