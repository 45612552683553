// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.input-toggle {
  &__label {
    font-size: 1.6rem;
    color: getColor("grey-light-1400");
    font-weight: getWeight("thick");
    display: inline-block;
  }
}

.toggle-box {
  @include flex-box($align-items: center);
  line-height: 18px;
  margin-top: 2.2rem;
  color: getColor("grey-light-1800");

  &__false {
    color: getColor("grey-light-2000");
  }

  &__btn-wrapper {
    width: 50px;
    height: 25px;
    border-radius: 30px;
    margin-right: 1.5rem;
    margin-left: 1.5rem;
    padding: 0.2rem 0.3rem;
    background: getColor("grey-light-2000");
    cursor: pointer;
    transition: all 0.3s linear;
    @include flex-box(flex-start, center);

    &--active {
      background: rgba(21, 250, 17, 0.69);
      justify-content: flex-end;
    }
  }

  &__btn {
    width: 20px;
    height: 100%;
    border-radius: 100px;
    background: getColor("light-color");
  }
}
