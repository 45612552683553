// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.video-feedback-modal-form {
  max-width: 370px !important;
  margin-top: 6rem;
  margin-bottom: 3.5rem;

  &__btn {
    font-size: 1.5rem !important;
    font-weight: getWeight("extra-thick") !important;
  }
}
