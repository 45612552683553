// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.card {
  background: getColor("light-color");
  box-shadow: 0px 10px 60px rgba(38, 45, 118, 0.08);
  border-radius: 20px;
  position: relative;
  height: 428px;
  @include flex-box($flex-direction: column);

  @include respond(tab-port) {
    height: 390px;
  }

  &__header {
    min-height: 150px;
    padding: 7.5rem 2rem 0px 2rem;
  }

  &__photo {
    vertical-align: middle;
    position: absolute;
    top: -47px;
    left: 50%;
    transform: translateX(-50%);
  }

  &__title {
    text-align: center;
    font-weight: getWeight("thick");
    font-size: 2rem;
    line-height: 30px;
    color: getColor("secondary-color");
  }

  &__main {
    margin-top: 1.8rem;
    padding: 1.8rem 1.2rem 3rem 1.2rem;
    height: 100%;
    @include flex-box(space-between, center, $flex-direction: column);

    @include respond(tab-port) {
      margin-top: 1rem;
      padding-left: 1.7rem;
      padding-right: 1.7rem;
    }
  }

  &__content {
    font-size: 1.73rem;
    text-align: center;
    line-height: 27px;
  }

  &__btn {
    font-size: 1.7rem;
    font-weight: getWeight("thick");
  }

  &__action {
    position: absolute !important;
    top: 24px;
    right: 2rem;
  }
}
