// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.form {
  &__group {
    &:not(:last-child) {
      margin-bottom: 1.6rem;
    }
  }

  &__group--inline {
    @include flex-box(space-between, center, $flex-wrap: wrap);
  }

  &__label {
    font-weight: getWeight("extra-thick");
    font-size: 2rem;
    line-height: 30px;
    letter-spacing: -0.02em;
    display: inline-block;
  }

  &__control {
    line-height: 24px;
    letter-spacing: 0.12em;
    padding: 2.1rem 2.3rem 1.7rem 2.3rem;
    background: getColor("light-color");
    border: 1px solid rgba(82, 76, 76, 0.38);
    border-radius: 10px;
    display: block;
    font-family: inherit;
    font-size: 1.6rem;
    width: 100%;
    transition: all 0.3s linear;

    &:focus {
      outline: none;
      border-color: getColor("primary-color");
    }

    &::placeholder {
      color: rgba(82, 76, 76, 0.38);
    }
  }

  &__control--textarea {
    resize: none;
    min-height: 95px;
  }

  &__control--error {
    border-color: getColor("danger-color") !important;
  }

  &__group--inline &__error-wrapper {
    text-align: end;
    width: 100%;
  }

  &__error-wrapper {
    margin-top: 0.3rem;
  }

  &__error {
    text-align: right;
    font-size: 1.2rem;
    line-height: 18px;
    color: getColor("danger-color");
  }

  // Input Secondary
  &__group--secondary {
    position: relative;
  }

  &__group--secondary &__label {
    font-size: 1.6rem;
    color: getColor("grey-light-1400");
    font-weight: getWeight("thick");
  }

  &__group--secondary &__control {
    border-radius: 0px;
    border: none;
    border-bottom: 0.5px solid rgba(getColor("dark-color"), 0.8);
    padding: 1.9rem 0px 0.8rem 0px;
    color: getColor("grey-light-1400");
    background: transparent;

    &:read-only:focus {
      border-color: rgba(getColor("dark-color"), 0.8);
    }

    &:focus {
      border-color: getColor("primary-color");
    }
  }

  &__group--secondary &__control--textarea {
    min-height: 100%;
    border: 1px solid rgba(getColor("dark-color"), 0.8);
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 3px;
  }

  &__group--secondary &__control--textarea + &__action {
    right: 1rem;
  }

  &__action {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.4rem;
    line-height: 15px;
    color: getColor("accent-2100");
    cursor: pointer;
  }

  // Input Tertiary
  &__group--tertiary &__label {
    font-size: 1.4rem;
  }

  &__group--tertiary &__control {
    padding: 1.2rem 2rem;
    font-size: 1.3rem;
    border-color: getColor("grey-light-1900");

    &::placeholder {
      line-height: 18px;
      color: getColor("accent-1300");
    }

    &:focus {
      border-color: getColor("primary-color");
    }
  }
}
