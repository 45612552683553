// ABSTRACTS
@import "../../../../../assets/sass/abstracts/variables";
@import "../../../../../assets/sass/abstracts/functions";
@import "../../../../../assets/sass/abstracts/mixins";

.add-subject-modal-base {
  text-align: center;
  min-width: 694px !important;
  max-width: 694px !important;

  &__photo {
    vertical-align: middle;
    margin-bottom: 2.9rem;
  }
}
