// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.book-private-session-info {
  background: getColor("grey-light-1700");
  border-radius: 10px;
  padding: 1.7rem 2.5rem 7rem 2.5rem;

  &__staff-profile {
    @include flex-box($align-items: center);
    margin-bottom: 3rem;
  }

  &__staff-photo {
    vertical-align: middle;
    margin-right: 3rem;
  }

  &__staff-name {
    font-weight: getWeight("extra-thick");
    font-size: 2.5rem;
    line-height: 30px;
    letter-spacing: 0.04em;
  }

  &__org-role {
    font-weight: getWeight("thick");
    font-size: 2rem;
    line-height: 20x;
  }

  &__group {
    &:not(:last-of-type) {
      margin-bottom: 3rem;
    }
  }

  &__heading {
    font-weight: getWeight("extra-thick");
    font-size: 2.2rem;
    line-height: 45px;
    letter-spacing: 0.04em;
    text-decoration-line: underline;
    margin-bottom: 0.4rem;
  }

  &__text {
    max-width: 371px;
    font-weight: getWeight("thin");
  }

  &__item {
    @include flex-box($align-items: center);

    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  &__item-icon {
    color: getColor("primary-color");
    margin-right: 1rem;
    font-size: 2rem;
  }
}
