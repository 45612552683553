.secondary-hero {
  background: getColor("accent-1200");
  padding-top: 7.3rem;
  padding-bottom: 11.5rem;

  &__container {
    text-align: center;
    max-width: 1069px;
  }

  &__heading {
    margin-bottom: 4.1rem;
  }

  &__description {
    margin-left: auto;
    margin-right: auto;
    max-width: 810px;
    font-size: 2.2rem;
    line-height: 39px;

    @include respond(phone) {
      line-height: 30px;
    }
  }

  &__btn-group &__btn:first-child {
    margin-right: 3rem;

    @include respond(phone) {
      margin-right: 0px;
    }
  }

  &__btn {
    font-size: 2.1rem;
    line-height: 33px;
    margin-top: 4.1rem;

    @include respond(phone) {
      margin-top: 2.2rem;
    }
  }
}
