.spacer {
  font-size: 2rem;
  line-height: 30px;
  letter-spacing: 0.12em;
  text-align: center;
  margin-top: 0.9rem;
  position: relative;

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    border: 1px solid rgba(getColor("accent-100"), 0.38);
    position: absolute;
    top: 50%;
    z-index: 1;
  }

  &__text {
    position: relative;
    background: getColor("grey-light-100");
    display: inline-block;
    padding: 0px 1rem;
    z-index: 5;
  }
}
