// ABSTRACTS
@import "../../../../assets/sass/abstracts/variables";
@import "../../../../assets/sass/abstracts/functions";
@import "../../../../assets/sass/abstracts/mixins";

.add-student-subjects-modal-base {
  min-width: 1237px !important;

  &__title {
    font-weight: getWeight("extra-thick");
    font-size: 2.5rem;
    line-height: 60px;
  }

  &__header {
    margin-bottom: 2.6rem;
  }

  &__levels {
    margin-top: 1.5rem;
  }

  &__actions {
    margin-top: 3rem;
    @include flex-box(space-between, center);
  }

  &__checkbox-label {
    font-weight: getWeight("thick");
    line-height: 20px;
    color: getColor("accent-2000");
  }

  &__checkbox-wrapper {
    margin-left: -12px;
  }
}
