// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.stats-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 1.7rem;
}
