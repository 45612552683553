body {
  font-size: getBaseFont("font-size");
  font-family: getBaseFont("font-family");
  font-weight: getBaseFont("font-weight");
  line-height: getBaseFont("line-height");
  color: getBaseFont("color");
}

.primary-heading {
  font-weight: getWeight("bold");
  font-size: 4rem;
  line-height: 75px;
  letter-spacing: 0.04em;

  @include respond(tab-land) {
    font-size: 3.8rem;
    line-height: 40px;
  }

  @include respond(tab-port) {
    font-size: 3rem;
    line-height: 35px;
  }
}

.student-base-heading {
  font-size: 2.6rem;
  line-height: 39px;
  font-weight: getWeight("thick");
}
