// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.student-sidenavbar {
  height: 100%;

  @include respond(tab-land) {
    width: 0px;
    visibility: hidden;
    opacity: 0;
  }

  @media screen and (min-width: 1400px) {
    width: 0px;
    visibility: hidden;
    opacity: 0;
  }

  &--open {
    position: fixed;
    left: 0;
    top: 0;
    opacity: 1 !important;
    visibility: visible !important;
    width: 100% !important;
    z-index: 2;
    background: rgba(getColor("dark-color"), 0.4);
  }

  &__container {
    @include flex-box($flex-direction: column);
    width: 174px;
    height: 100%;
    background: getColor("dark-color");
    color: getColor("grey-light-1600");
  }

  &__navbar,
  &__nav-list,
  &__container {
    height: 100%;
  }

  &__brand {
    @include flex-box(center, center);
    padding: 1.8rem 1rem 4.6rem 1rem;
    width: 150px;
    height: 128.81px;
  }

  &__nav-list {
    @include flex-box(space-between, $flex-direction: column);
  }

  &__nav-item {
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }

  &__nav-link {
    padding: 1.4rem 0.9rem;
    @include flex-box($align-items: center);
    font-size: 1.5rem;
    font-weight: getWeight("extra-thick");
    line-height: 18px;
    transition: all 0.3s linear;

    &:hover,
    &--active {
      background: getColor("accent-1600");
    }
  }

  &__nav-link--logout {
    &:hover {
      background: getColor("secondary-color");
    }
  }

  &__nav-icon {
    margin-right: 0.8rem;
    font-size: 1.8rem;
    color: getColor("light-color");

    &--home {
      font-size: 2.3rem;
    }
  }
}
