// ABSTRACTS
@import "../../../../assets/sass/abstracts/variables";
@import "../../../../assets/sass/abstracts/functions";
@import "../../../../assets/sass/abstracts/mixins";

.pricing-card {
  padding: 2.2rem 2.3rem;
  background: getColor("light-color");
  box-shadow: 0px 10px 60px rgba(38, 45, 118, 0.08);
  border-radius: 20px;
  @include flex-box($align-items: center);

  &:not(:last-child) {
    margin-bottom: 1.1rem;
  }

  @include respond(phone) {
    flex-direction: column;
    text-align: center;
  }

  &__price {
    font-weight: getWeight("extra-thick");
    font-size: 2.7rem;
    line-height: 60px;
    color: getColor("secondary-color");
    margin-right: 5rem;

    @include respond(phone) {
      margin-right: 0;
      line-height: 37px;
    }
  }

  &__right {
    flex-grow: 1;
    @include flex-box(space-between, $align-items: center);

    @include respond(phone) {
      flex-direction: column;
      text-align: center;
    }
  }

  &__content {
    font-weight: getWeight("thick");
    font-size: 2rem;
    line-height: 30px;
    color: getColor("grey-light-1800");

    @include respond(phone) {
      margin-bottom: 1rem;
    }
  }

  &__btn {
    font-size: 2rem !important;
    line-height: 33px !important;
  }
}
