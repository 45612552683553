// ABSTRACTS
@import "../../../../../assets/sass/abstracts/variables";
@import "../../../../../assets/sass/abstracts/functions";
@import "../../../../../assets/sass/abstracts/mixins";

.level-list {
  display: grid;
  grid-template-columns: repeat(3, 305px);
  gap: 2.7rem;
}
