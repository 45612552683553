// ABSTRACTS
@import "../../../../assets/sass/abstracts/variables";
@import "../../../../assets/sass/abstracts/functions";
@import "../../../../assets/sass/abstracts/mixins";

.dashboard-filter-modal {
  .MuiDialog-paper {
    @include flex-box();
    padding: 1rem !important;
  }

  &__calendar {
    @include flex-box();
  }
}
