// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.primary-timestamp {
  width: 260px;
  background: rgba(217, 217, 217, 0.73);
  border-radius: 15px;
  @include flex-box(space-between, center);
  padding: 1.9rem 2.2rem;
  cursor: pointer;
  transition: background 0.1s linear, color 0.1s linear;

  @include respond(tab-port) {
    width: 225px;
  }

  &:hover,
  &--active {
    color: getColor("light-color");
    background: getColor("primary-color");
  }

  &__time {
    font-weight: getWeight("bold");
    font-size: 1.8rem;
    line-height: 22px;
  }

  &__icon {
    font-size: 2.3rem;
    color: getColor("primary-color");
  }

  &:hover &__icon,
  &--active &__icon {
    color: getColor("light-color");
  }
}
