// ABSTRACTS
@import "../../../assets/sass/abstracts/variables";
@import "../../../assets/sass/abstracts/functions";
@import "../../../assets/sass/abstracts/mixins";

.pricing-subject-base {
  margin-top: 5.4rem;

  &__top {
    @include flex-box(space-between, flex-start);
    margin-bottom: 6.9rem;

    @include respond(tab-port) {
      flex-direction: column;
    }
  }

  &__levels {
    flex: 1;

    @include respond(tab-port) {
      order: 2;
      width: 100%;
    }
  }

  &__checkout {
    @include flex-box($align-items: flex-end, $flex-direction: column);

    @include respond(tab-port) {
      width: 100%;
      justify-content: flex-end;
      margin-bottom: 1.8rem;
    }
  }

  &__price {
    font-weight: getWeight("thick");
    font-size: 1.5rem;
    line-height: 22px;
    margin-bottom: 0.5rem;
  }

  &__price-amount {
    font-weight: getWeight("bold");
    font-size: 2.2rem;
    line-height: 33px;
    text-decoration-line: underline;
    margin-left: 0.4rem;
    display: inline-block;
  }

  &__btn {
    font-weight: getWeight("thick") !important;
    font-size: 1.5rem !important;
    line-height: 22px !important;
  }

  &__heading {
    font-weight: getWeight("thick");
    font-size: 3rem;
    line-height: 20px;
    margin-bottom: 3.7rem;
  }

  &__bottom {
    padding-bottom: 3.8rem;
  }
}
